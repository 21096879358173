<template>
    <div v-if="signal !== SignalTypes.NORMAL" class="signal">
        <div :class="['signal-icon', { 'no-text': hideText }]">
            <SvgIcon
                :class="[
                    'icon',
                    {
                        'icon-size-medium': !hideText,
                        'icon-size-huge': hideText,
                    },
                ]"
                :iconId="`signal-${signal}`"
                verticalAlign="baseline"
            />
        </div>
        <p v-if="!hideText" :class="['signal-text', `signal-text-${signal}`]">{{ $t(`ui.payment.deposit.signal.${signal}`) }}</p>
    </div>
</template>

<script>
import { paymentMethodStatus } from '@/modules/payment';

const SignalTypes = {
    NORMAL: 'normal',
    DELAYED: 'delayed',
    UNAVAILABLE: 'unavailable',
};

export default {
    name: 'Signal',
    data() {
        return {
            SignalTypes,
        };
    },
    computed: {
        signal() {
            switch (this.status) {
                case paymentMethodStatus.OPERATIONAL:
                    return SignalTypes.NORMAL;
                case paymentMethodStatus.DEGRADED_PERFORMANCE:
                    return SignalTypes.DELAYED;
                case paymentMethodStatus.MAJOR_OUTAGE:
                case paymentMethodStatus.UNDER_MAINTENANCE:
                case paymentMethodStatus.CLOSED:
                    return SignalTypes.UNAVAILABLE;
                default:
                    return SignalTypes.NORMAL;
            }
        },
    },
    props: {
        status: {
            type: String,
            default: paymentMethodStatus.OPERATIONAL,
            required: true,
            validator(value) {
                return Object.values(paymentMethodStatus).includes(value);
            },
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.signal {
    display: flex;
    flex-direction: row;

    @include all_but_mini {
        align-items: center;
    }

    &-icon {
        width: 16px;
        height: 16px;

        &.no-text {
            margin-top: -3px;
            width: 20px;
            height: 20px;
        }
    }

    &-text {
        @include only_mini {
            margin-top: 2px;
        }
        margin-left: 4px;
        @extend %caption-font-700;
        color: $golden-brown;

        &-unavailable {
            color: $dark-red;
        }
    }
}
</style>
