<template>
    <div class="chips">
        <h2 class="chips-title">{{ $t('ui.chips.cashIn.title') }}</h2>
        <div class="chips-sub-title">{{ $t('ui.chips.cashIn.subTitle') }}</div>
        <div class="notify error" v-if="boxErrorMessage">{{ boxErrorMessage }}</div>
        <div class="chips-content">
            <div class="chips-details">
                <div class="chips-item">
                    <div class="chips-item-text">{{ $t('ui.chips.cashIn.betPawaChips') }}</div>
                    <Currency
                        class="value bold"
                        :amount="cashInTaxDetails.pawaChips || 0"
                        :format="chips.currency"
                        :isLoading="isFetchCashInTaxLoading"
                    />
                </div>
                <div class="chips-item">
                    <div class="chips-item-text">{{ $t('ui.chips.cashIn.taxableWinnigs') }}</div>
                    <Currency
                        class="value bold"
                        :amount="cashInTaxDetails.winnings || 0"
                        :format="currency"
                        :isLoading="isFetchCashInTaxLoading"
                    />
                </div>
                <div class="chips-item" v-if="isCashInTaxAmountValid">
                    <div class="chips-item-text">{{ $t('ui.chips.cashIn.wht', { percentage: cashInTaxDetails.taxPercentage }) }}</div>
                    <Currency
                        class="value bold"
                        :amount="cashInTaxDetails.taxAmount"
                        :format="currency"
                        :isLoading="isFetchCashInTaxLoading"
                    >
                        -
                    </Currency>
                </div>
                <div class="chips-item chips-payout">
                    <div class="chips-item-text">{{ $t('ui.chips.cashIn.payout') }}</div>
                    <Currency class="value bold" :amount="cashInTaxDetails.payout || 0" :format="currency" />
                </div>
            </div>
        </div>
        <button @click="handleSubmit()" :disabled="isCashInBtnDisabled" class="button-confirm button button-full button-primary">
            {{ $t('ui.chips.cashIn.cashInButton') }}
        </button>
        <div @click="$modal.hide('chips-modal')" class="button-cancel">{{ $t('ui.common.cancel') }}</div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { action as platformAction, getter as platformGetter } from '@agi.packages/platform';
import { Currency } from '@agi.packages/core/components';
import { action as generalAction, mutation as generalMutation } from '@/store/store';
import { isNumber } from '@/modules/core/utils/number/isNumber';
import { getter as coreGetter } from '@/modules/core';

const notification = {
    type: 'verify',
    data: {
        id: 'verifySuccess',
        trigger: true,
    },
};

export default {
    name: 'ChipsCashIn',
    components: { Currency },
    data: function () {
        return {
            boxErrorMessage: null,
        };
    },
    created() {
        this.getChipsCashInTaxPreview();
    },
    computed: {
        ...mapState({
            currency: (state) => state.platform.settings.currency,
            chips: (state) => state.platform.settings.chips,
            error: (state) => state.platform.chips.errorMessage,
        }),
        ...mapGetters({
            cashInTaxDetails: platformGetter.GET_CASH_IN_TAX_DETAILS,
            isLoading: coreGetter.IS_LOADING,
        }),
        isCashInTaxAmountValid() {
            return isNumber(this.cashInTaxDetails.taxAmount) && this.cashInTaxDetails.taxAmount > 0;
        },
        isFetchCashInTaxLoading() {
            return this.isLoading(platformAction.GET_CHIPS_CASH_IN_TAX_PREVIEW);
        },
        isCashInPossible() {
            return this.cashInTaxDetails.payout > 0;
        },
        isCashInBtnDisabled() {
            return this.isFetchCashInTaxLoading || !this.isCashInPossible;
        },
    },
    methods: {
        ...mapActions({
            getChipsCashInTaxPreview: platformAction.GET_CHIPS_CASH_IN_TAX_PREVIEW,
            chipBalanceExchange: platformAction.CHIPS_BALANCE_EXCHANGE,
            notificationMessage: generalAction.NOTIFY,
        }),
        ...mapMutations({
            clearNotification: generalMutation.CLEAR_NOTIFICATIONS,
        }),
        handleSubmit() {
            this.boxErrorMessage = null;
            this.clearNotification();
            if (!this.isCashInPossible) return;
            this.chipBalanceExchange({ amount: this.cashInTaxDetails.payout }).then(() => {
                this.$modal.hide('chips-modal');
                this.notificationMessage({
                    ...notification,
                    data: {
                        ...notification.data,
                        message: this.$t('ui.chips.cashIn.notification', {
                            amount: this.$options.filters.currency(this.cashInTaxDetails.payout, '%s'),
                        }),
                    },
                });
            });
        },
    },
    watch: {
        error: {
            deep: true,
            handler(value) {
                this.boxErrorMessage = value;
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.chips {
    &-payout {
        padding-top: 8px;
        border-top: 0.5px solid #e6e7e2;
    }
}
</style>
