<template>
    <div class="rich-text-components">
        <DynamicComponent v-for="(item, index) in getContent" v-bind:key="index" :data="item" :path="'content'" />
    </div>
</template>
<script>
import { DynamicComponent } from '@agi.packages/platform/components';
import { RICH_TEXT_DISPLAY_ONLY_ON_BACKGROUND } from '@/components/content/richtext-const';

export default {
    name: 'RichText',
    components: { DynamicComponent },
    props: ['data', 'isDarkBackground'],
    computed: {
        getContent() {
            // If this component is displayed on dark background we want to NOT show content with `onlyOnLightBackground` values.
            const acceptedDisplayOnlyOnBackground = this.isDarkBackground
                ? RICH_TEXT_DISPLAY_ONLY_ON_BACKGROUND.onlyOnDarkBackground
                : RICH_TEXT_DISPLAY_ONLY_ON_BACKGROUND.onlyOnLightBackground;
            // So if the value of `displayOnlyOnBackground` is: null (old content), `anyBackground` or it matches our ^ variable
            // then we return the content.
            // If it doesn't match, eg. on dark-bg footer there is a content for light-bg only, then we return empty array and not disaply it.
            if (
                this.data.content.displayOnlyOnBackground &&
                this.data.content.displayOnlyOnBackground !== RICH_TEXT_DISPLAY_ONLY_ON_BACKGROUND.anyBackground &&
                this.data.content.displayOnlyOnBackground !== acceptedDisplayOnlyOnBackground
            ) {
                return [];
            }
            const items = JSON.parse(this.data.content.content).content || [];
            return items.map((item) => {
                return { ...item, visibility: item.attrs?.visibility };
            });
        },
    },
};
</script>

<style scoped lang="scss">
.rich-text-components {
    h3 {
        margin: 16px 0 8px;
    }
    p {
        line-height: 1.4;
    }
}
</style>
<style lang="scss">
.rich-text-components {
    table tr th p,
    table tr td p {
        word-break: break-all;
    }
}
</style>
