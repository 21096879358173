<template>
    <div class="booking-code">
        <slot name="toolbar" v-if="toolbarVisible" />
        <div v-else class="booking-code-form" v-show="inputVisible && isBookingCodeLoadingEnabled">
            <div class="label" v-if="labelVisible">
                <span>{{ $t('ui.bookingCode.bookingCode') }} </span>
            </div>
            <InputField
                class="booking-code-field"
                labelClass="bold"
                name="booking-code"
                type="text"
                formName="booking-code"
                :value="bookingCode"
                @value="bookingCode = $event"
                :attrs="{ min: 0, disabled: false, placeholder: $t('ui.bookingCode.placeholder') }"
                :v="$v.bookingCode"
                :errorMessages="bookingCodeRequired"
                :disableOnInputValidation="true"
            >
            </InputField>
            <div v-if="error" class="notify error">
                <renderer :input="error" />
            </div>
            <button class="button button-primary button-full" @click="getBookingSelection()">
                {{ $t('ui.bookingCode.load') }}
            </button>
        </div>
        <div v-show="isSuccessMessageVisible" class="notify success">
            {{ $t('ui.bookingCode.successfulLoad') }}
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { deviceType } from '@agi.packages/core';
import { InputField } from '@agi.packages/core/components';
import { betslip } from '@agi.packages/sport';

export default {
    name: 'BookingCode',
    components: { InputField },
    props: {
        toolbarVisible: {
            type: Boolean,
            default: false,
        },
        inputVisible: {
            type: Boolean,
            default: false,
        },
        labelVisible: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        bookingCode: null,
        isSuccessMessageVisible: false,
        disableBookingCodeLoading: false,
    }),
    validations: {
        bookingCode: { required },
    },
    computed: {
        ...mapState({
            error: (state) => state.sport.betslip.booking.loaded.error,
            loadedSelection: (state) => state.sport.betslip.booking.loaded.selection,
            isBookingCodeLoadingEnabled: (state) => state.sport.betslip.booking.ui.isBookingCodeLoadingEnabled,
            bookingCodeRequired() {
                return {
                    required: this.$t('ui.bookingCode.bookingCodeRequired'),
                };
            },
        }),
        ...mapGetters({
            selectionIds: betslip.getter.GET_SELECTION_IDS,
        }),
    },
    methods: {
        getBookingSelection() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.$v.$reset();
            this.$emit('load');
            this.$store.dispatch(betslip.action.GET_BOOKING_SELECTION, this.bookingCode);
            this.$gtm.query({
                event: 'booking_code',
                code: this.bookingCode,
            });
        },
    },
    watch: {
        loadedSelection(selection) {
            if (selection.length && !this.error) {
                this.$emit('success');
                this.isSuccessMessageVisible = true;
                this.$store.commit(betslip.mutation.TOGGLE_BOOKING_CODE_LOADING);
                this.bookingCode = null;
                if (!deviceType.isPresto()) {
                    setTimeout(() => {
                        this.isSuccessMessageVisible = false;
                    }, 5000);
                }
            }
        },
        selectionIds: {
            immediate: true,
            handler(selection, oldSelection) {
                const isSelectionChanged = oldSelection && oldSelection.length !== selection.length;
                if (isSelectionChanged) {
                    this.$store.commit(betslip.mutation.RESET_LOADED_BOOKING_CODE_ERRORS);
                }
                if (!selection.length && !this.isBookingCodeLoadingEnabled) {
                    this.$store.commit(betslip.mutation.TOGGLE_BOOKING_CODE_LOADING);
                }
            },
        },
    },
    beforeDestroy() {
        this.$store.commit(betslip.mutation.RESET_LOADED_BOOKING_CODE_ERRORS);
    },
};
</script>

<style lang="scss" scoped>
.booking-code {
    @extend %body-normal-font-400;
    margin: 12px;
    &-field {
        margin-bottom: 12px;
    }
    &-form {
        background: $light-grey-bg;
        padding: 16px;
        .notify {
            margin: 0 0 12px;
        }
    }

    & + ::v-deep .bet-bonus .betslip-legbonus-info {
        margin-top: 0;
    }

    .label {
        margin-bottom: 4px;
    }
}
</style>
