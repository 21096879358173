<template>
    <p class="text-block" :style="style">
        <DynamicComponent v-for="(item, index) in data.content" :key="index" :data="item" path="content" />
    </p>
</template>
<script>
export default {
    name: 'Textblock',
    props: ['data'],
    computed: {
        style() {
            const { backgroundColor, padding } = this.data?.attrs || {};
            return {
                'background-color': backgroundColor,
                padding: padding,
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.text-block {
    ::v-deep .ordered-list ol {
        margin-left: 5px;
    }
}
</style>
