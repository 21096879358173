<template>
    <Tabs :tabs="tabs" :active="activeTab" @select="handleTabClick($event)" class="auth-tabs">
        <Tab :name="tabType.LOGIN">
            <LoginForm :isRouterTabLink="isRouterTabLink" @handleClick="setActiveTab" @success="onSuccess" />
        </Tab>
        <Tab :name="tabType.JOIN_NOW">
            <JoinNowForm :isRouterTabLink="isRouterTabLink" @handleClick="setActiveTab" @success="onSuccess" />
        </Tab>
    </Tabs>
</template>

<script>
import { authType, auth } from '@agi.packages/platform';
import { Tabs, Tab } from '@agi.packages/core/components';
import LoginForm from './LoginForm.vue';
import JoinNowForm from './JoinNowForm.vue';
import { routeName } from '@/router/const-name';

export default {
    name: 'AuthWrapper',
    components: { Tab, Tabs, LoginForm, JoinNowForm },
    props: {
        tabName: {
            type: String,
            default: authType.LOGIN,
        },
        isRouterTabLink: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tabType: authType,
            activeTab: {
                key: authType.LOGIN,
            },
            loginHref: this.$router.resolve({ name: routeName.LOGIN }).href,
            joinHref: this.$router.resolve({ name: routeName.JOIN_NOW }).href,
        };
    },
    created() {
        this.setActiveTab({ key: this.tabName });
    },
    computed: {
        tabs() {
            return [
                {
                    text: this.$t('ui.common.logIn'),
                    iconClass: 'icon-size-medium',
                    key: authType.LOGIN,
                    iconId: !this.$mq.isVerySmall && 'icon-logIn-menu',
                    href: this.loginHref,
                },
                {
                    text: this.$t('ui.common.joinNow'),
                    iconClass: 'icon-size-medium',
                    key: authType.JOIN_NOW,
                    iconId: !this.$mq.isVerySmall && 'icon-join-now',
                    href: this.joinHref,
                },
            ];
        },
    },
    methods: {
        activeTabDetails(key) {
            return this.tabs.find((item) => item.key === key);
        },
        handleTabClick({ key }) {
            const { href } = this.activeTabDetails(key);
            if (this.$route.path === href) {
                return;
            }
            this.$gtm.query({
                event: this.$gtm.makeValidEventName(`${key}_click`),
                click_element: 'tab',
            });

            if (this.isRouterTabLink) {
                this.$router.push({ path: href });
                return;
            }
            this.setActiveTab({ key });
        },

        setActiveTab({ key }) {
            this.activeTab = this.activeTabDetails(key);
        },
        onSuccess() {
            if (![this.loginHref, this.joinHref].includes(this.$route.path)) {
                this.$store.dispatch(auth.action.GET_BALANCE, { force: true });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.auth-tabs {
    ::v-deep .tabs-menu .tabs-selector.active {
        &.first {
            border-left: 1px solid $border-color;
        }

        &.last {
            border-right: 1px solid $border-color;
        }
    }

    .tab-section {
        position: relative;
        padding: 16px;
        border-bottom: 1px solid $border-color;

        // hack for OM
        &:before,
        &:after {
            position: absolute;
            top: 0;
            width: 1px;
            height: 100%;
            background: $border-color;
            content: '';
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }
}
</style>
