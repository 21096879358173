<template>
    <form class="page" v-on:submit.prevent>
        <h2>{{ $t(`ui.common.${pinOrPassword}.forgotten`) }}</h2>
        <span class="sub-header">
            {{
                otpTimer && !isPresto
                    ? $t(`ui.resetPassword.waitFewMoments`)
                    : $t(`ui.resetPassword.enterYourMobileNumber`, { brandName: $t('project.brand') })
            }}
        </span>
        <renderer class="notify error" v-if="!inProgress && error" :input="error" />
        <PhoneNumber
            formName="reset-password-form"
            :value="phoneNumber"
            @value="phoneNumber = $event"
            :v="$v.phoneNumber"
            v-bind="{
                ...(phoneNumberValidation && {
                    placeholder: phoneNumberValidation.placeholder,
                    errorMessages: { phoneNumberFormat: $t(phoneNumberValidation.errorMessageKey) },
                }),
            }"
        />

        <CaptchaWidget :name="$options.name" @error="trackCloudflareFailures" />

        <button
            @click="requestNewPassword()"
            class="button button-submit button-full"
            data-test-id="requestNewPassword"
            :disabled="(!token && !isPresto) || (otpTimer && !isPresto) || inProgress || (!isPresto && $v.$invalid)"
        >
            {{ otpTimer && !isPresto ? $t(`ui.resetPassword.requestOtpIn`) : $t(`ui.resetPassword.requestOtp`) }}
            <Timer :start="otpTimer" :seconds="otpCountdownDuration" @update="setOtp" label=" :" :hidden="isPresto" />
        </button>
        <div class="forgot-page-footer">
            <DynamicSlot :component="footerSlot" />
        </div>
    </form>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { helper, deviceType, getter as coreGetter } from '@agi.packages/core';
import { auth, action as platformAction, getter as platformGetter, strapiPageSlots } from '@agi.packages/platform';

import { getter } from '@/store/modules/translations';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import PhoneNumber from '@/components/PhoneNumber';
import Timer from '@/components/Timer';
import { DynamicSlot } from '@agi.packages/platform/components';
import { routeName } from '@/router/const-name';

export default {
    name: 'ResetPassword',
    components: { PhoneNumber, Timer, DynamicSlot },
    mixins: [PageMixin, SEOMixin],
    data() {
        return {
            phoneNumber: null,
            isPresto: deviceType.isPresto(),
            footerSlot: strapiPageSlots.FORGOT_FOOTER_SLOT_NAME,
        };
    },
    validations() {
        return {
            phoneNumber: {
                required,
                ...(this.phoneNumberValidation && { phoneNumberFormat: this.phoneNumberValidation.validator }),
            },
        };
    },
    computed: {
        ...mapState({
            authError: (state) => state.platform.auth.error,
            phonePrefix: (state) => state.platform.settings?.brandIdentity?.phoneCountryCode,
            storedPhoneNumber: (state) => state.platform.auth.phoneNumber,
        }),
        ...mapGetters({
            otpTimer: auth.getter.GET_OTP_TIMER,
            pinOrPassword: getter.PIN_OR_PASSWORD,
            token: auth.getter.SECURED_TOKEN,
            country: platformGetter.GET_COUNTRY,
            captchaError: auth.getter.GET_CAPTCHA_ERROR,
            otpCountdownDuration: auth.getter.GET_OTP_COUNTDOWN_SECONDS,
            isOtpAttemptsLimited: auth.getter.IS_OTP_ATTEMPTS_LIMITED,
            phoneNumberValidation: platformGetter.GET_PHONE_NUMBER_VALIDATION,
        }),
        inProgress() {
            return this.$store.getters[coreGetter.IS_LOADING](auth.action.RESET_PASSWORD);
        },
        error() {
            return this.captchaError || this.authError;
        },
    },
    methods: {
        ...mapMutations({
            setOtp: auth.mutation.SET_OTP_TIMER,
        }),
        requestNewPassword: function () {
            if (this.isPresto && this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store
                .dispatch(auth.action.RESET_PASSWORD, {
                    phoneNumber: this.phoneNumber,
                    areaCode: this.country,
                })
                .then(() => {
                    this.$router.push({
                        name: routeName.RESET,
                        params: {
                            verificationCodeSent: true,
                            phoneNumber: this.phoneNumber || null,
                            isVerificationModalOpened: this.isOtpAttemptsLimited,
                        },
                    });
                });
        },
        trackCloudflareFailures(reason) {
            this.$gtm.query({
                event: 'reset_password_cloudflare_failure',
                reason,
            });
        },
    },
    watch: {
        storedPhoneNumber: {
            immediate: true,
            handler() {
                this.phoneNumber = helper.removeCountryCode(this.storedPhoneNumber || this.$route.params.phoneNumber, this.phonePrefix);
            },
        },
    },
    mounted() {
        if (this.phoneNumberValidation && this.phoneNumber) this.$v.phoneNumber.$touch();
    },
    created() {
        this.$store.dispatch(auth.action.RESET_ERROR);
        this.$store.dispatch(platformAction.GET_CONTENT, this.footerSlot);
        this.$store.dispatch(auth.action.GENERATE_RESET_PASSWORD_TRACKING_ID);
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch(auth.action.RESET_ERROR);
        this.$store.commit(auth.mutation.SET_PHONE_NUMBER, {
            phoneNumber: this.phoneNumber,
            phonePrefix: this.phonePrefix,
        });
        next();
    },
};
</script>

<style scoped lang="scss">
.forgot-page-footer {
    margin-top: 8px;
}
</style>
