<template>
    <div class="tournament-item">
        <TournamentsList class="light-panel-header">
            <template v-slot="{ tournaments }">
                <FilterCheckboxItem
                    checkboxId="all-tournaments"
                    :checked="isAllCompetitionsChecked({ competitions: tournaments })"
                    @onCheck="onAllCompetitionsCheck({ checked: $event, competitions: tournaments })"
                    :label="$t('menus.all')"
                    :counter="tournamentsTotalCount"
                />
                <FilterCheckboxItem
                    v-for="(tournament, index) in tournaments"
                    :key="`tournament-${tournament.id}-${index}`"
                    :checkboxId="tournament.id"
                    :checked="isCompetitionChecked({ competition: tournament })"
                    @onCheck="onCompetitionCheck({ checked: $event, competition: tournament })"
                    :label="tournament.name"
                    :counter="tournament.eventCount"
                />
            </template>
        </TournamentsList>
        <CountryMenus class="light-panel-header">
            <template v-slot="{ menu }">
                <MenuItem
                    v-for="(country, index) in menu.data"
                    :key="`${menu.slug}-country-${country.region.id}-${index}`"
                    class="sub-menu"
                    :selected="isExpandedCountry(country.region.id)"
                    @onItemClick="toggleCountry(country.region.id)"
                    :item="{
                        text: country.region.name,
                        control: {
                            text: country.eventCount,
                            icon: isExpandedCountry(country.region.id) ? 'icon-arrow-up' : 'arrow_down',
                        },
                    }"
                >
                    <template slot="icon">
                        <ImageIcon
                            v-if="country.region.slug"
                            class="icon-status"
                            :src="country.region.slug && `img/flags/${country.region.slug}.png`"
                            :altSrc="country.region.parentSlug && `img/flags/${country.region.parentSlug}.png`"
                        />
                    </template>
                    <template slot="content">
                        <ExpansionPanel v-if="country.competitions" :expanded="isExpandedCountry(country.region.id)">
                            <FilterCheckboxItem
                                :checkboxId="`all-${country.region.name}-${country.region.id}`"
                                :checked="isAllCompetitionsChecked({ competitions: country.competitions })"
                                @onCheck="onAllCompetitionsCheck({ checked: $event, competitions: country.competitions })"
                                :label="$t('menus.all')"
                                :counter="country.eventCount"
                            />
                            <FilterCheckboxItem
                                v-for="(competition, competitionIndex) in country.competitions"
                                :key="`${menu.slug}-country-filter-${competition.slug}-${competitionIndex}`"
                                :checkboxId="`competition-${competitionIndex}-${country.region.id}`"
                                :checked="isCompetitionChecked({ competition })"
                                @onCheck="onCompetitionCheck({ checked: $event, competition })"
                                :label="competition.name"
                                :counter="competition.eventCount"
                            />
                        </ExpansionPanel>
                    </template>
                </MenuItem>
            </template>
        </CountryMenus>
    </div>
</template>

<script>
import { FilterCheckboxItem, TournamentsList, CountryMenus } from '@agi.packages/sport/components';
import { MenuItem, ExpansionPanel } from '@agi.packages/core/components';

export default {
    name: 'FilterByTournament',
    components: { TournamentsList, FilterCheckboxItem, CountryMenus, MenuItem, ExpansionPanel },
    props: {
        tournaments: {
            type: Array,
            required: true,
        },
        competitions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            expandedCountries: [],
        };
    },
    methods: {
        isAllCompetitionsChecked({ competitions }) {
            return !!competitions?.every((i) => this.competitions.includes(i.id));
        },
        onAllCompetitionsCheck({ checked, competitions }) {
            this.$emit('onAllCheck', { checked, competitions });
        },
        isCompetitionChecked({ competition }) {
            const { id } = competition;
            return this.competitions.includes(id);
        },
        onCompetitionCheck({ checked, competition }) {
            this.$emit('onCheck', { checked, competition });
        },
        isExpandedCountry(countryRegionId) {
            return this.expandedCountries.includes(countryRegionId);
        },
        toggleCountry(countryRegionId) {
            if (this.isExpandedCountry(countryRegionId)) {
                this.expandedCountries = this.expandedCountries.filter((i) => i !== countryRegionId);
            } else {
                this.expandedCountries.push(countryRegionId);
            }
        },
    },
    computed: {
        tournamentsTotalCount() {
            return this.tournaments.reduce((accumulator, current) => accumulator + (current.eventCount || 0), 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.tournament-item {
    .light-panel-header:first-child {
        &::v-deep {
            .title {
                border-top: none;
            }
        }
    }
}

.light-panel-header {
    &::v-deep {
        &.expansion-panel,
        & > .expansion-panel {
            .title {
                background: $white-bg;
                padding: 12px;

                h3 {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}

.sub-menu {
    &::v-deep {
        &.menu-item.selected > .link {
            font-weight: 500;
            color: inherit;

            svg.svg-icon {
                fill: $main-text;
            }
        }
    }
}
</style>
