<template>
    <div class="main-container-presto">
        <MobileToolbar :class="{ 'right-sidebar-opened': isRightSidebarOpen }" />
        <Header />
        <slot name="section:top"></slot>
        <div class="view-wrapper" id="view-wrapper">
            <slot name="section:terms"></slot>
            <template v-if="!showTermsAndConditions">
                <div class="view-wrapper-content">
                    <slot name="section:left" />
                    <div class="main-content" v-if="!sidebarOpen">
                        <div class="center-view" v-if="!betslipOpen">
                            <slot name="section:center" />
                        </div>
                        <slot name="section:right" />
                    </div>
                </div>
                <slot name="section:center:bottom" />
            </template>
        </div>
        <slot name="section:bottom" />
    </div>
</template>

<script>
import MobileToolbar from '@/components/Fragments/MobileToolbar.vue';
import Header from '@/components/Header.vue';

export default {
    name: 'AppLayoutPresto',
    components: {
        MobileToolbar,
        Header,
    },
    props: ['showTermsAndConditions', 'sidebarOpen', 'betslipOpen', 'isRightSidebarOpen'],
};
</script>

<style lang="scss">
.main-container-presto {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: scroll;
}

.view-wrapper {
    overflow-y: auto;

    @include ios_le_12 {
        -webkit-overflow-scrolling: touch;
    }

    &-overlay {
        position: fixed;
        background: rgb(0 0 0 / 60%);
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        flex: 1 1 auto;
        z-index: 999;
    }
}

.main-content {
    width: 100%;
    display: flex;
    flex: 1 0 83%;
    @include only_mini {
        display: table;
        flex: 1;
    }
}

.router-view {
    position: relative;
}

.center-view {
    position: relative;
    overflow: hidden;
    flex: 1;
    width: 100%;
}

.side-bar.leftMenu {
    flex: 1 0 17%;
}
</style>
