<template>
    <ErrorPage
        :imgSrc="imgSrc"
        :button="{ text: buttonText, emit: true }"
        @button:click="buttonClick"
        :title="errorMessage.title"
        :sectionList="errorMessage.description ? [errorMessage.description] : []"
    />
</template>

<script>
import ErrorPage from '@/components/Pages/ErrorPage.vue';
import { ERROR_IMAGES } from '@/components/content/content-const';
import { routeName } from '@/router/const-name';

const buttonStates = {
    GO_TO_HOME_PAGE: 'goToHomePage',
    GO_TO_UPCOMING_PAGE: 'goToUpcomingPage',
    RELOAD_PAGE: 'reloadPage',
};
const errorCodes = [
    'SB_EVENT_NOT_FOUND',
    'SPORTSBOOK_NOT_FOUND',
    'EVENTS_NOT_FOUND',
    'EVENTS_LOGO_UPLOADING_ERROR',
    'EVENTS_UNSUPPORTED_IMAGE_SIZE',
    'EVENTS_PRICING_PRICE_NOT_FOUND',
    'SB_MAX_EVENTS_PER_QUERY_EXCEEDED',
    'EVENTS_INVALID_SPORTS_ID',
    'EVENTS_PRICING_GROUP_NOT_FOUND',
    'EVENTS_REGION_NOT_FOUND',
    'EVENTS_UNABLE_TO_RESET_EVENT',
    'EVENTS_SHARE_PAGE_ERROR',
];
export default {
    name: 'EventNetworkError',
    components: { ErrorPage },
    props: {
        error: {
            type: Object,
            required: true,
        },
        isLive: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        imgSrc() {
            if (this.isNetworkError) {
                return '';
            } else if (this.is404Error) {
                return ERROR_IMAGES.error404;
            }
            return ERROR_IMAGES.errorUnexpected;
        },
        isNetworkError() {
            return !this.error.errorCode && this.error.message === 'Network Error';
        },
        is404Error() {
            return this.error.errorCode === 'SPORTSBOOK_NOT_FOUND';
        },
        errorMessage() {
            if (this.isNetworkError) {
                return {
                    title: this.$t('errors.NETWORK_ERROR'),
                    description: this.$t('ui.eventPage.error.description.networkError'),
                };
            }
            if (this.error.errorCode === 'EVENTS_NOT_FOUND' || this.error.errorCode === 'SB_EVENT_NOT_FOUND') {
                return {
                    title: this.$t('ui.eventPage.error.title.invalidLink'),
                    description: this.$t(`errors.${this.error.errorCode}`),
                };
            }
            if (this.is404Error) {
                return {
                    title: this.$t('errors.SPORTSBOOK_NOT_FOUND'),
                    description: this.$t('ui.eventPage.error.description.sportsBookNotFound'),
                };
            }
            const errorText = this.isLive ? 'liveEventUnexpectedError' : 'unexpectedError';
            return {
                title: this.$t('ui.eventPage.error.title.unexpectedError'),
                description: errorCodes.includes(this.error.errorCode)
                    ? this.$t(`errors.${this.error.errorCode}`)
                    : this.$t(`ui.eventPage.error.description.${errorText}`),
            };
        },
        buttonState() {
            const isLiveUnexpectedError = this.isLive && !this.error.errorCode && !this.isNetworkError;
            if (isLiveUnexpectedError) {
                return buttonStates.GO_TO_HOME_PAGE;
            }
            if (this.error.errorCode === 'EVENTS_NOT_FOUND' || this.error.errorCode === 'SB_EVENT_NOT_FOUND') {
                return buttonStates.GO_TO_HOME_PAGE;
            }
            if (this.is404Error) {
                return buttonStates.GO_TO_UPCOMING_PAGE;
            }
            return buttonStates.RELOAD_PAGE;
        },
        buttonText() {
            if (this.buttonState === buttonStates.GO_TO_HOME_PAGE) {
                return this.$t('ui.common.home');
            }
            if (this.buttonState === buttonStates.GO_TO_UPCOMING_PAGE) {
                return this.$t('ui.common.upcomingEvents');
            }
            return this.$t('critical.refreshPage');
        },
    },
    methods: {
        reloadPage() {
            window.location.reload();
        },
        buttonClick() {
            if (this.buttonState === buttonStates.GO_TO_HOME_PAGE) {
                this.$router.push({ name: routeName.HOMEPAGE });
            } else if (this.buttonState === buttonStates.GO_TO_UPCOMING_PAGE) {
                this.$router.push({ name: routeName.UPCOMING });
            } else {
                this.reloadPage();
            }
        },
    },
};
</script>
