<template>
    <div class="header" v-sniffer="{ handler: handlePageScroll }">
        <div class="header-body">
            <div ref="top">
                <div class="main" :class="{ 'main-full-logo': !isCompactLogo }">
                    <div class="header-logo-section">
                        <div>
                            <a
                                class="sport-menu row-cell align-middle"
                                v-if="!isPresto"
                                @click="openMenu"
                                :class="{ 'router-link-active': sidebarOpen }"
                            >
                                <SvgIcon
                                    iconId="icon-hamburger"
                                    iconFallback="icons/icon-hamburger"
                                    class="menu-icon"
                                    verticalAlign="top"
                                />
                            </a>
                            <router-link class="logo-link" :class="{ 'logo-link-compact': isCompactLogo }" :to="{ name: 'Homepage' }">
                                <SvgIcon
                                    v-if="isCompactLogo"
                                    iconId="logo-short"
                                    iconFallback="logo"
                                    class="logo-short"
                                    verticalAlign="middle"
                                />
                                <SvgIcon v-else iconId="logo" iconFallback="logo" class="logo" verticalAlign="top" />
                            </router-link>
                            <span v-if="ageLimitRegulationContent" class="age-limit-regulation">
                                <ImageIcon :src="ageLimitRegulationContent.icon" class="icon-size-very-large" />
                                <span v-if="!$mq.isBeforeSSmall" class="age-limit-text">{{ ageLimitRegulationContent.information }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="header-balance-section" style="display: none">
                        <div v-if="!isPresto" :class="['icon-search', { 'search-open': mobileSearchOpen }]">
                            <SvgIcon
                                @click.native="toggleMobileSearch()"
                                iconId="icon-search"
                                class="icon-size-medium"
                                verticalAlign="unset"
                            />
                        </div>
                        <HeaderBalance :isCompact="isCompactBalance"></HeaderBalance>
                        <div v-if="$mq.isXXMediumMin && !isPresto" class="top-menu">
                            <TopMenu />
                        </div>
                    </div>
                </div>
                <div class="search">
                    <SearchBar v-show="mobileSearchOpen" @toggleMobileSearch="toggleMobileSearch()" />
                </div>
            </div>
            <div v-if="logoSubtextContent.length" class="subtext">
                <div v-for="(item, index) in logoSubtextContent" :key="'sub-text-' + index">
                    <DynamicComponent :data="item" path="content" />
                </div>
            </div>
        </div>
        <TopMenuDropdown v-if="$mq.isVerySmall" />
        <div class="header-footer" :class="{ fixed: isContentFixed }">
            <div class="header-content-section">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getter, action as generalAction, auth } from '@agi.packages/platform';
import { action } from '@/store/store';
import SearchBar from '@/components/SearchBar.vue';
import HeaderBalance from '@/components/Fragments/HeaderBalance';
import { helper, deviceType } from '@agi.packages/core';
import TopMenuDropdown from '@/components/Fragments/TopMenuDropdown.vue';
import TopMenu from '@/components/Fragments/TopMenu.vue';
import scrollSniffer from '@/js/directives/ScrollSniffer';

const SLOT_LOGO_SUBTEXT = 'LOGO_SUBTEXT';
const shortBalance = {
    TABLET: 99999999,
    MOBILE: 999,
};

export default {
    name: 'Header',
    components: { TopMenu, SearchBar, HeaderBalance, TopMenuDropdown },
    directives: {
        sniffer: scrollSniffer,
    },
    computed: {
        ...mapState({
            sidebarOpen: (state) => state.ui.sidebarOpen,
            mobileSearchOpen: (state) => state.ui.mobileSearchOpen,
        }),
        ...mapGetters({
            getContentGetter: getter.GET_CONTENT_SLOTS,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            isForceGetContent: getter.IS_FORCE_GET_CONTENT,
            brandPreference: getter.GET_BRAND_PREFERENCE,
            balance: auth.getter.GET_BALANCE,
            currentUserStatus: getter.GET_CURRENT_USER_STATUS,
            ageLimitRegulationContent: getter.GET_AGE_LIMIT_REGULATION_CONTENT,
        }),
        logoSubtextContent() {
            const subtext = this.getContentGetter[SLOT_LOGO_SUBTEXT];
            return (subtext && subtext.content) || [];
        },
        isNeedToRefreshContent() {
            const { content, isAuthenticated: isSlotAuthenticated } = this.getContentGetter[SLOT_LOGO_SUBTEXT] || {};
            return isSlotAuthenticated !== this.isAuthenticated && !this.isForceGetContent && content;
        },
        isEventPageHeaderTabsEnabled() {
            return !!helper.getObjectField(this.brandPreference, 'isEventPageHeaderTabsEnabled', false);
        },

        isHeaderFixed() {
            return this.$mq.isXXMediumMin;
        },
        isContentFixed() {
            return !this.isHeaderFixed && this.$mq.isXXMediumMin;
        },
        isTabletSize() {
            return this.$mq.isXXMediumMin;
        },
        isBigBalanceValue() {
            if (this.isTabletSize) {
                return this.balance > shortBalance.TABLET;
            }
            return this.balance > shortBalance.MOBILE;
        },
        isCompactBalance() {
            return this.isBigBalanceValue || this.$mq.isXSSmall;
        },
        isCompactLogo() {
            if (this.isPresto) {
                return false;
            }

            if (this.isBigBalanceValue || !this.isAuthenticated) {
                return this.$mq.isXXSmall;
            }
            return false;
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    methods: {
        trackTopNav(key) {
            this.$gtm.query({
                event: this.$gtm.makeValidEventName(`header_${key}_click`),
                click_element: 'button',
            });
        },
        trackTopNavMT(element) {
            if (element) {
                this.$gtm.query({
                    event: 'top_nav',
                    click_text: element,
                });
            }
        },
        openMenu() {
            this.$store.commit('closeMobileSearch');
            this.$store.dispatch(action.TOGGLE_SIDEBAR);
            this.trackTopNavMT('menu');
        },
        toggleMobileSearch() {
            this.$store.commit('toggleMobileSearch');
            this.trackTopNav('toggle-search');
        },
        handlePageScroll() {
            if (this.mobileSearchOpen) {
                this.$store.commit('toggleMobileSearch');
            }
        },
    },
    watch: {
        isNeedToRefreshContent(value) {
            if (value) {
                this.$store.dispatch(generalAction.REFRESH_CONTENT, SLOT_LOGO_SUBTEXT);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    position: relative;
    background: $betpawa-green right;
    text-align: left;

    &.fixed {
        position: fixed;
        z-index: 3;
        top: 0;
    }

    .main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex: 0 0 auto;
        padding: 8px;

        &-full-logo {
            @include all_but_mini {
                flex-wrap: nowrap;
            }
        }
    }

    .logo {
        border: 0;
        display: inline-block;

        &.svg-icon,
        &.image-icon {
            width: $header-brand-logo-medium-width;
            height: $header-brand-logo-medium-height;
        }

        @include oldschool {
            &.svg-icon,
            &.image-icon {
                width: $header-brand-logo-small-width;
                height: $header-brand-logo-small-height;
            }
        }
    }

    .header-buttons {
        display: inline-block;

        .button {
            padding: 8px 12px;
            line-height: 16px;

            &:last-child {
                border-radius: $border-radius-right;
            }

            @include main {
                padding: 7px 5px;
                font-size: 12px;
            }

            @include oldschool {
                font-size: 12px;
                padding: 4px;
            }
        }
    }

    .subtext {
        padding: 0 8px 8px;
        line-height: 14px;
    }

    .top-menu {
        display: inline-block;
        vertical-align: middle;
    }

    .topbar-nav-right {
        display: flex;
        align-items: center;
    }

    .presto & {
        .header-balance-section {
            justify-content: flex-start;
        }

        .logo-link {
            margin-left: 0;
            line-height: 0;
        }
    }
}

a.underlogo {
    text-decoration: none;
    font-style: italic;
    font-size: 12px;
    color: $header-underlogo-text;
    display: inline-block;
    &:hover {
        color: $header-underlogo-text;
    }
}

a.button {
    text-decoration: none;
    @include only_mini {
        text-decoration: none !important;
    }
}

.sport-menu {
    padding: 4px;
    display: inline-block;
    vertical-align: top;
}

.logo-link {
    margin-left: 12px;
    display: flex;

    @include all_but_mini {
        margin-right: 12px;
        overflow: hidden;
    }

    @include no_flex_box_supported {
        display: inline-block;
        vertical-align: top;

        &-compact {
            margin-left: 8px;
        }
    }
}

svg.menu-icon,
img.menu-icon {
    width: 16px;
    height: 16px;
    fill: map-get($bg-neutral, 'bg-lighten-2');
    display: block;
}
.icon-search {
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-right: 8px;

    svg {
        fill: $white-text;
    }
}

.header-footer {
    display: flex;

    &.fixed {
        position: absolute;
        z-index: 1;
    }
}

.logo-short {
    width: 32px;
    height: 18px;
    @include oldschool {
        width: 24px;
    }
}

.header-logo-section {
    @include all_but_mini {
        overflow: hidden;
    }

    > div {
        display: flex;
        align-items: center;
    }
}

.header-balance-section {
    display: inline-flex !important;
    align-items: center;
}

.age-limit-regulation {
    .age-limit-text {
        @extend %small-details-font-400;
        color: $dark-grey-3;
        margin-left: 8px;
    }
}
</style>
