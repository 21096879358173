<template>
    <div class="chips">
        <div class="chips-amount">
            <Currency class="value bold upperCase" :contrast="false" :amount="Number(balance)" :format="chips.currency" />
            <SvgIcon @click.native="openChipsInfo" class="icon-info" iconId="icon-info-white" />
        </div>
        <div>
            <button class="button chips-button" :class="{ 'green-button': hasPositiveBalance }" @click="$modal.show('chips-modal')">
                {{ hasPositiveBalance ? $t('ui.chips.notification.getBetpawaChips') : $t('ui.chips.header.convertChips') }}
            </button>
        </div>
        <Modal width="512px" class="chips-info" name="chips-info" @close="$modal.hide('chips-info')">
            <div class="chips-info-text"><renderer @clickEvent="clickEvent" :input="$t('ui.chips.header.readMore', { casinoName })" /></div>
        </Modal>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { auth } from '@agi.packages/platform';

import { Currency } from '@agi.packages/core/components';
import { getter as translationsGetter } from '@/store/modules/translations';

export default {
    name: 'ChipsHeader',
    components: { Currency },
    mounted() {
        this.getChipsBalance();
    },
    computed: {
        ...mapState({
            chips: (state) => state.platform.settings.chips,
        }),
        ...mapGetters({
            balance: auth.getter.GET_CHIPS_BALANCE,
            casinoName: translationsGetter.CASINO_NAME,
        }),
        hasPositiveBalance() {
            return this.balance === 0;
        },
    },
    methods: {
        ...mapActions({
            getChipsBalance: auth.action.GET_CHIPS_BALANCE,
        }),
        openChipsInfo() {
            this.$gtm.query({
                event: 'chips_tooltip_click',
            });
            this.$modal.show('chips-info');
        },
        clickEvent() {
            this.$gtm.query({
                event: 'chips_modal_read_more_click',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.chips {
    width: 100%;
    background: #2c3033;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    position: relative;
    &-info {
        width: auto;
        top: 50px;
        &-text {
            font-size: 14px;
        }
        ::v-deep .modal {
            &-container {
                padding-bottom: 16px;
            }
            &-body {
                padding-top: 6px;
            }
            &-header-button {
                margin: 8px 16px 0 0;
                svg {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }
    .icon-info {
        width: 12px;
        height: 12px;
        margin-left: 4px;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .button {
        &.chips-button {
            border: 1px solid white;
            background: #2c3033;
            color: white;
            padding: 7px 8px;
            &.green-button {
                border-color: #9ce800;
                color: #9ce800;
            }
        }
    }

    &-amount {
        display: flex;
        align-items: center;
        color: white;
        font-size: 14px;
        ::v-deep .symbol {
            color: #8dc63f;
        }
    }
}
</style>
