<template>
    <div v-if="isQuickLinkDisplayable" class="quick-links">
        <ul class="nav">
            <li v-for="link in links" :key="link.id" @click="trackLinkClick(link)" class="quick-links-item">
                <ExtendedLink :link="getQuickLink(link)" class="quick-links-link">
                    <SvgIcon v-if="link.icon" :iconId="link.icon" class="quick-links-icon" />
                    <div class="quick-links-text">{{ link.title }}</div>
                </ExtendedLink>
            </li>
        </ul>
    </div>
</template>

<script>
import { deviceType } from '@agi.packages/core';
import { ExtendedLink } from '@agi.packages/core/components';

export default {
    name: 'QuickLinks',
    props: ['data'],
    components: { ExtendedLink },
    data: function () {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        maxNumberOfLinks() {
            const { breakpoints } = this.content;
            return breakpoints[this.$mq.size];
        },
        links() {
            const { quickLinks } = this.content;
            return quickLinks.filter((link) => link.isLinkEnabled && (link.page.data || link.link)).slice(0, this.maxNumberOfLinks);
        },
        isQuickLinkDisplayable() {
            const { isQuickLinksEnabled } = this.content;
            return isQuickLinksEnabled && !this.isPresto && this.links.length;
        },
        content() {
            const { content } = this.data || {};
            return content || {};
        },
    },
    methods: {
        trackLinkClick(link) {
            const formatedLinksData = this.links.map((link) => {
                return {
                    icon: link.icon,
                    order: link.order,
                    title: link.title,
                };
            });

            this.$gtm.query({
                event: 'quick_link',
                all_widget_links: formatedLinksData,
                number_of_links: formatedLinksData.length,
                link_clicked_icon: link.icon,
                link_clicked_title: link.title,
                link_clicked_real_position: this.links.indexOf(link) + 1,
            });
        },
        getQuickLink(quickLink) {
            const {
                page: { data },
                link,
            } = quickLink;
            const actualLink = data && data.attributes.links.find((link) => link.isActual);
            const pageLink = actualLink ? `/${actualLink.link}` : '';

            return `${pageLink || link || ''}`;
        },
    },
};
</script>

<style scoped lang="scss">
.quick-links {
    margin: 0;
    padding: 0;

    .nav {
        margin: 0;
        padding: 16px 8px;
        text-align: center;
        list-style: none;
        display: flex;
        justify-content: space-around;

        &-small {
            padding: 14px 8px;
        }
    }

    &-item {
        position: relative;
        margin: 0;
        padding: 0;
        display: inline-block;
        text-align: center;
        flex-grow: 1;

        &:last-child:after {
            display: none;
        }

        &:after {
            display: block;
            content: '';
            width: 1px;
            height: 40px;
            position: absolute;
            right: -1px;
            top: 5px;
            background-color: #8e9398;
        }
    }

    &-icon {
        width: 46px;
        height: 28px;
        margin-bottom: 8px;
        fill: $top-menu-icon-color;
    }

    &-text {
        color: $top-menu-icon-color;
        line-height: 14px;
        text-transform: uppercase;
        font-size: 12px;
    }

    &-link {
        &:hover,
        &:focus {
            .quick-links-icon {
                fill: $menu-item-color-selected;
            }

            .quick-links-text {
                color: $menu-item-color-selected;
            }
        }
    }
}
</style>
