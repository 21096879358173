<template>
    <RangeSlider
        :minText="$t('ui.common.minLabel')"
        :maxText="$t('ui.common.maxLabel')"
        :numberFormatter="numberFormat"
        @input="onValueChanged"
        v-bind="$attrs"
    />
</template>

<script>
import RangeSlider from '@/components/RangeSlider.vue';
import { numberFormat } from '@/modules/core/utils/helper';

export default {
    name: 'LabeledRangeSlider',
    methods: {
        numberFormat,
        onValueChanged(v) {
            this.$emit('input', v);
        },
    },
    components: { RangeSlider },
};
</script>
