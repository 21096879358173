<template>
    <span class="timer" v-if="countdown && !hidden">
        <span class="label" v-if="label">{{ label }}</span>
        <span class="number">{{ formattedCountdown }}</span>
    </span>
</template>
<script>
import { helper } from '@/modules/core';

export default {
    name: 'Timer',
    props: {
        start: {
            type: [Number, Date],
        },
        label: {
            type: String,
            default: '',
        },
        seconds: {
            type: Number,
            default: 0,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
        isMinutesVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            countdown: null,
            interval: null,
        };
    },
    computed: {
        formattedCountdown() {
            if (this.isMinutesVisible) {
                return helper.formatSecondsToMinutes(this.countdown);
            }
            return this.countdown;
        },
    },
    watch: {
        start: {
            immediate: true,
            handler(start) {
                clearInterval(this.interval);
                const seconds = start && start + this.seconds * 1000 - new Date().getTime();
                if (seconds && seconds >= 0) {
                    this.countdown = seconds && Math.ceil(seconds / 1000);
                    this.interval =
                        !this.hidden &&
                        setInterval(
                            function () {
                                this.countdown--;
                                if (this.countdown <= 0) {
                                    clearInterval(this.interval);
                                    this.$emit('update');
                                }
                            }.bind(this),
                            1000
                        );
                } else {
                    this.$emit('update');
                }
            },
        },
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
};
</script>

<style scoped lang="scss">
.timer {
    color: $orange;
}
</style>
