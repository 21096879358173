<template>
    <Link :data="data" :id="data.id" />
</template>
<script>
import Link from './Link';
export default {
    name: 'Hashtag',
    components: { Link },
    props: ['data'],
};
</script>
