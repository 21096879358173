<template>
    <div>
        <div class="deposit-fee-summary text" v-if="isTelcoFeeVisible">
            <div class="row">
                <span>{{ $t('ui.payment.deposit.telcoFee.depositAmount') }}:</span>
                <Currency class="right" :amount="amount" :format="currencyFormat" :contrast="false" :data-test-id="`depositAmount`" />
            </div>
            <div class="deposit-fee-summary-row">
                <span>{{ $t('ui.payment.deposit.telcoFee.providerFee', { providerName }) }}:</span>
                <Currency
                    class="right"
                    :amount="telcoFee.feeAmount || 0"
                    :format="currencyFormat"
                    :isLoading="isLoading"
                    :contrast="false"
                    :data-test-id="`depositTelcoFee`"
                />
            </div>
            <div class="deposit-fee-summary-row" v-if="telcoFee.compensationAmount">
                <span>{{ $t('ui.payment.deposit.telcoFee.compensationBonus') }}:</span>
                <Currency
                    class="right bold bonus"
                    :amount="telcoFee.compensationAmount"
                    :format="currencyFormat"
                    :isLoading="isLoading"
                    :contrast="false"
                    :data-test-id="`depositTelcoCompensationAmount`"
                >
                    <template v-slot:amount>
                        <span>+</span>
                    </template>
                </Currency>
            </div>
            <div class="deposit-fee-summary-row">
                <span>{{ $t('ui.payment.deposit.telcoFee.totalBalanceAdded') }}:</span>
                <Currency
                    class="right bold"
                    :amount="telcoFee.totalAmount || 0"
                    :format="currencyFormat"
                    :isLoading="!telcoFee.totalAmount || isLoading"
                    :contrast="false"
                    :data-test-id="`finalDepositAmount`"
                />
            </div>
        </div>
        <div class="deposit-fee-summary-notify notify warning" v-else>
            <renderer :input="$t('ui.payment.deposit.telcoFee.calculationFailed')" />
        </div>
    </div>
</template>

<script>
import { Currency } from '@agi.packages/core/components';
import { mapState } from 'vuex';

export default {
    name: 'DepositTelcoFee',
    components: { Currency },
    props: {
        amount: {
            type: Number,
            required: true,
        },
        providerName: {
            type: String,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        telcoFee: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency,
        }),
        isTelcoFeeVisible() {
            return this.isLoading || !!this.telcoFee.totalAmount;
        },
    },
};
</script>

<style scoped lang="scss">
.deposit-fee-summary {
    padding-bottom: 8px;

    &-row {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &-notify {
        margin: 0 0 8px 0;
    }
}

.currency {
    text-transform: uppercase;
    color: $main-text;
    white-space: nowrap;
    margin-left: 3px;

    &.bonus {
        color: $green-success;
    }
}
</style>
