<template>
    <div class="refer-a-friend">
        <PromoBanner
            :title="$t('ui.referAFriend.title')"
            :subTitle="$t('ui.referAFriend.subtitle')"
            :items="icons"
            :button="{ route: { path: route }, text: $t('ui.referAFriend.referAFriend') }"
        >
        </PromoBanner>
    </div>
</template>
<script>
const PromoBanner = () => import('@/components/Fragments/PromoBanner');

export default {
    name: 'Referafriend',
    components: { PromoBanner },
    props: ['data'],
    data: function () {
        return {
            route: '/refer',
            icons: [{ icon: 'icon-promo-coins' }],
        };
    },
};
</script>
