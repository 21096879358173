<template>
    <span>
        <span class="telco-fee-label" @click.prevent="onClick">
            {{ $t('ui.payment.deposit.telcoFee.noFee', { amount: formattedCompensationAmount }) }}
            <span class="info">
                <SvgIcon class="icon-size-small" icon-id="icon-info" verticalAlign="text-top" />
            </span>
        </span>
        <Modal
            class="telco-fee-info"
            name="telco-fee-info"
            width="100%"
            :avoidCollisionWithFooter="true"
            @close="$modal.hide(`telco-fee-info`)"
        >
            {{ $t('ui.payment.deposit.telcoFee.compensationInfo', { providerName, amount: formattedCompensationAmount }) }}
        </Modal>
    </span>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'DepositTelcoFeeLabel',
    props: {
        providerName: {
            type: String,
            required: true,
        },
        compensationAmount: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
        formattedCompensationAmount() {
            return this.$options.filters.currency(this.compensationAmount, this.currencyFormat, 0);
        },
    },
    methods: {
        onClick() {
            this.$modal.show('telco-fee-info');
            this.$gtm.query({ event: 'compensation_bonus_info_click' });
        },
    },
};
</script>

<style lang="scss" scoped>
.telco-fee {
    &-label {
        color: $disabled-text;
        cursor: pointer;
    }
    &-info {
        bottom: 0;
    }
}
.info {
    vertical-align: top;
}
</style>
