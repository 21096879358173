<template>
    <PrestoImageWrapper v-intersection-observer="{ callback: onVisible, once: true }">
        <Banner @click.native="trackBannerClick" :data="bannerData"></Banner>
    </PrestoImageWrapper>
</template>
<script>
import { mapGetters } from 'vuex';
import { auth, getter as platformGetter } from '@agi.packages/platform';
import { helper, deviceType } from '@agi.packages/core';
import PrestoImageWrapper from '@/components/Fragments/PrestoImageWrapper';
import Banner from '@/components/content/Banner';

export default {
    name: 'BannersCollection',
    components: { PrestoImageWrapper, Banner },
    props: {
        data: Object,
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            isImpressionOnVisible: false, // TODO: let's remove this `isImpressionOnVisible` hard-code in [BP-27141]
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            country: platformGetter.GET_COUNTRY,
        }),
        bannersList() {
            const { bannerCollectionItems } = this.data.content;
            const bannersWithData = bannerCollectionItems.filter((item) => {
                const hasData = !!item.banner?.data?.attributes;
                const hasWeight = item.weight && item.weight > 0;
                const isAvailable = !!item.banner?.data?.attributes.isBannerEnabled;
                const { startDate, endDate } = item.schedule || {};
                const currentTime = new Date().getTime();
                const passStartDate = startDate ? currentTime > new Date(startDate).getTime() : true;
                const passEndDate = endDate ? new Date(endDate).getTime() > currentTime : true;

                return isAvailable && hasData && hasWeight && passStartDate && passEndDate;
            });

            return bannersWithData;
        },
        currentItem() {
            const item = helper.weightedRandom(this.bannersList);
            return item?.banner?.data.attributes;
        },
        currentHref() {
            const { url, page } = this.currentItem || {};
            const actualLink = page?.data?.attributes.links?.find((link) => link.isActual)?.link;
            return (actualLink && `/${actualLink}`) || url || '';
        },
        bannerData() {
            return {
                content: {
                    ...this.currentItem,
                    isCustomImageFormat: true,
                    link: this.currentHref,
                    image: {
                        data: [
                            {
                                ...this.currentItem.image.data,
                            },
                        ],
                    },
                },
            };
        },
    },
    methods: {
        trackBannerClick() {
            const { title, slug } = this.currentItem || {};
            this.$gtm.query({
                event: 'banner_collection_click',
                link: this.currentHref,
                title,
                slug,
                isAuthenticated: this.isAuthenticated,
                jurisdiction: this.country,
            });
        },
        trackBannerImpresshion() {
            const { title, slug } = this.currentItem || {};
            this.$gtm.query({
                event: 'banner_collection_impression',
                link: this.currentHref,
                title,
                slug,
                isAuthenticated: this.isAuthenticated,
                jurisdiction: this.country,
                isPresto: this.isPresto,
            });
        },
        onVisible() {
            if (this.isImpressionOnVisible) {
                // TODO: let's remove this `isImpressionOnVisible` hard-code in [BP-27141]
                this.trackBannerImpresshion();
            }
        },
    },
    mounted() {
        if (this.isPresto || !this.isImpressionOnVisible) {
            // TODO: let's remove this `isImpressionOnVisible` hard-code in [BP-27141]
            this.trackBannerImpresshion();
        }
    },
};
</script>
