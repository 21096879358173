<template>
    <Tabs :tabs="cashOutTabs" :active="activeTab" truncate-text class="cashout-tabs" @select="setActiveTab">
        <Tab :name="cashOutType.CASHOUT">
            <NewCashOut v-show="!hideTabContent" />
        </Tab>
        <Tab :name="cashOutType.AUTO_CASHOUT">
            <AutoCashOut v-show="!hideTabContent" />
        </Tab>

        <template #tab-prefix="{ tab }">
            <Badge v-if="tab.showNewBadge" :text="$t('ui.common.new')" type="new" />
        </template>
        <template #tab-suffix="{ tab }">
            <div>
                <SvgIcon v-if="tab.showCheckIcon" icon-id="icon-circle-checked-filled" class="tab-check-icon icon-size-small" />
            </div>
        </template>
    </Tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import Tabs from '@/modules/core/components/atoms/Tabs.vue';
import { cashOutType } from '@/modules/sport/const/cashout-const';
import { Tab } from '@/modules/core/components';
import NewCashOut from '@/modules/sport/components/Fragments/Cashout/NewCashOut.vue';
import AutoCashOut from '@/modules/sport/components/Fragments/Cashout/AutoCashOut.vue';
import { sport } from '@agi.packages/sport';
import Badge from '@/components/Badge.vue';
import { getObjectField } from '@/modules/core/utils/helper';

export default {
    name: 'CashOutTabs',
    components: { Badge, AutoCashOut, NewCashOut, Tab, Tabs },
    props: {
        hideTabContent: {
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters({
            autoCashout: sport.getter.GET_AUTO_CASHOUT_OFFER,
        }),
        cashOutTabs() {
            return [
                {
                    text: this.$t('ui.cashout.cashout'),
                    key: cashOutType.CASHOUT,
                },
                {
                    text: this.$t('ui.cashout.autoCashout'),
                    key: cashOutType.AUTO_CASHOUT,
                    showNewBadge: true,
                    showCheckIcon: getObjectField(this.autoCashout, 'enabled'),
                    iconClass: 'icon-auto-cashout',
                },
            ];
        },
    },
    data() {
        return {
            cashOutType,
            activeTab: {
                key: cashOutType.CASHOUT,
            },
        };
    },
    methods: {
        setActiveTab({ key }) {
            const { activeTab } = this.$route.query;
            if (this.isPresto && key === cashOutType.CASHOUT && activeTab) {
                this.$router.replace({ query: null });
            }
            this.activeTab.key = key;
            this.$emit('tabChanged', key);
        },
    },
    mounted() {
        const { activeTab } = this.$route.query;
        this.setActiveTab({ key: activeTab || cashOutType.CASHOUT });
    },
};
</script>

<style scoped lang="scss">
.cashout-tabs {
    border: 1px solid $medium-grey;
    border-top-width: 0;
    margin-top: 8px;

    .tab-check-icon {
        position: relative;
        top: -1px;
        margin-left: 4px;
        min-width: 14px;
        fill: $green-success;
    }
}

::v-deep .icon-auto-cashout {
    height: 16px;
    width: 32px;
}

::v-deep .tabs-selector {
    display: flex;
    justify-content: center;
    align-items: center;
}

// TODO: it should be properly fixed system wide in ticket https://aliengain.atlassian.net/browse/BP-30824
::v-deep input {
    height: 32px;
}

::v-deep button {
    height: 32px;
    padding: 0 10px;
}
</style>
