<template>
    <div class="text">
        <h2 class="title">{{ $t('ui.selfExclusion.title') }}</h2>
        <p class="description text">{{ $t('ui.selfExclusion.description') }}</p>
        <form v-if="isAuthenticated" class="form-wrapper" @submit.prevent>
            <Password
                :formName="formNameId"
                class="form-field"
                :v="$v.form.password"
                :value="form.password"
                :helpDisabled="true"
                @value="form.password = $event"
            />

            <div v-if="!inProgress && authError" class="notify error">
                <renderer :input="authError" />
            </div>

            <input
                type="submit"
                @click="exclude()"
                class="button button-submit button-full"
                data-test-id="confirmSelfExclusion"
                :disabled="inProgress"
                :value="$t(`ui.common.confirm`).toUpperCase()"
            />
        </form>
        <div @click="onCancel()" class="cancel-button underline" data-test-id="closeSelfExclusion">
            {{ $t(`ui.common.cancel`) }}
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Password from '@/components/Password.vue';
import { required, minLength, numeric } from 'vuelidate/lib/validators';
import { auth, getter as platformGetter } from '@agi.packages/platform';
import { getter as coreGetter } from '@agi.packages/core';
import ModalPlugin from '@/js/plugins/modal';
import { routeName } from '@/router/const-name';

export default {
    name: 'SelfExclusion',
    components: { Password },
    props: {
        modalRefs: {
            type: [Object, undefined],
            required: false,
        },
        modalRefKey: {
            type: String,
            required: false,
        },
    },
    data: () => {
        return {
            formNameId: 'self-exclusion-form',
            form: {
                password: '',
            },
        };
    },
    validations() {
        const isPin = this.brandPreference.pin;
        const validators = {
            form: {
                password: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                },
            },
        };
        return validators;
    },
    computed: {
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            authError: auth.getter.GET_AUTH_ERROR,
            isLoading: coreGetter.IS_LOADING,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        inProgress() {
            return this.isLoading(auth.action.SELF_EXCLUDE);
        },
        modalRef() {
            return this.modalRefs?.[this.modalRefKey] && this.modalRefs?.[this.modalRefKey][0];
        },
        errorMessages() {
            const required = this.$t('ui.common.form.error.required');

            return {
                password: { required },
            };
        },
    },
    methods: {
        exclude() {
            this.$v.form.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const payload = {
                password: this.form.password,
            };
            this.$store.dispatch(auth.action.SELF_EXCLUDE, payload).then((excluded) => {
                this.$gtm.query({
                    event: 'self_exclude_confirm_button_click',
                    wrongPinOrPass: !!this.authError,
                });
                if (excluded) {
                    this.closeModal(true);
                }
            });
        },
        closeModal(avoidListeners = false) {
            this.$emit('close', avoidListeners);
        },
        onCancel() {
            this.$gtm.query({ event: 'self_exclude_cancel_button_click' });
            this.closeModal(true);
        },
        onModalClose() {
            this.$store.commit(auth.mutation.RESET_ERROR);
        },
        onAuthError() {
            this.$router.push({ name: routeName.LOGIN, query: { returnPath: this.$route.fullPath } });
            this.closeModal();
        },
    },
    created() {
        if (!this.isAuthenticated) this.onAuthError();
        this.$store.dispatch(auth.action.RESET_ERROR);
    },
    watch: {
        isAuthenticated(value) {
            if (!value) this.onAuthError();
        },
    },
    mounted() {
        ModalPlugin.event.$on('hide', this.onModalClose);
    },
    beforeDestroy() {
        ModalPlugin.event.$off('hide', this.onModalClose);
    },
};
</script>

<style scoped lang="scss">
.text {
    line-height: 1.2em;
}

.title {
    text-align: center;
    font-size: 18px;
    line-height: 23.4px;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-weight: $font-weight-500;
}

.description {
    margin-bottom: 16px;
    margin-top: 0;
    line-height: 1.2em;
}

.form-field {
    margin-bottom: 12px;
}

.cancel-button {
    @extend %generic_button;
    width: 100%;
    max-width: none;
    margin-top: 8px;
    cursor: pointer;
    font-weight: $font-weight-500;
    text-transform: none;
    text-decoration: underline;
}
</style>
