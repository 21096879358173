<template>
    <div class="dropdown">
        <span class="dropdown-item pointer" @click="toggleOpen()" v-click-outside:[clickOutside]="handleOutside">
            <SvgIcon v-if="iconId" :iconId="iconId" class="dropdown-icon icon-size-medium" />
            <slot>
                <span class="label" :class="{ underline: isLabelUnderline }" :id="`${name}-dropdown-open`" v-if="label">{{ label }}</span>
            </slot>
        </span>
        <div class="dropdown-menu" v-if="isOpen" :style="{ minWidth: `${menuWidth}px` }" :class="{ 'right-sided': rightSided }">
            <MenuItem
                v-for="({ key, value }, index) in items"
                :is-capitalize-menu-titles="isCapitalizeMenuTitles"
                :key="`-dropdown-item-${index}`"
                :selected="value === selected"
                :item="{
                    name: name || '',
                    selector: key,
                    text: key || value,
                    control: { icon: 'icon-check', iconSize: 'icon-size-medium', iconDisabled: value !== selected },
                }"
                @click.native="$emit('value', value)"
            />
        </div>
    </div>
</template>

<script>
import MenuItem from '../atoms/MenuItem';

export default {
    name: 'DropDown',
    components: { MenuItem },
    props: {
        selected: {
            type: [String, Object],
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        clickOutside: {
            type: Boolean,
            default: true,
        },
        rightSided: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => [],
        },
        menuWidth: {
            type: Number,
            default: 176,
        },
        name: {
            type: String,
            default: '',
        },
        isLabelUnderline: {
            type: Boolean,
            default: true,
        },
        iconId: {
            type: String,
            default: '',
        },
        isCapitalizeMenuTitles: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        isEnabled() {
            return this.clickOutside && this.isOpen;
        },
    },
    methods: {
        handleOutside() {
            this.isOpen && this.$emit('toggle');
        },
        toggleOpen() {
            this.$emit('toggle');
        },
    },
};
</script>
<style lang="scss" scoped>
.dropdown {
    position: relative;

    ::v-deep .menu-item {
        > .link {
            border: none;
        }

        .control {
            min-width: 16px;
        }

        .menu-text.name {
            font-weight: normal;
            font-size: 14px;
        }
    }

    &-menu {
        position: absolute;
        z-index: 3;
        box-shadow: 0 2px 8px rgb(103 103 103 / 25%);
        margin-top: 6px;
        background: $white-bg;

        &.right-sided {
            right: 0;
        }
    }

    .label {
        @extend %body-normal-font-400;
        vertical-align: middle;

        @at-root .uppercase {
            text-transform: uppercase;
        }
    }

    .dropdown-icon {
        margin-right: 4px;
        vertical-align: middle;
    }
}
</style>
