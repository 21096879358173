<template>
    <span class="loader"></span>
</template>

<script>
export default {
    name: 'Loader',
};
</script>

<style scoped lang="scss">
.loader {
    background: transparent url('./../../assets/default-spinner.gif') no-repeat center center;
    width: 14px;
    height: 14px;
    background-size: contain;
    display: inline-block;
    vertical-align: sub;
}
</style>
