<template>
    <SelfExclusion @close="onClose" class="page"></SelfExclusion>
</template>

<script>
import { deviceType } from '@agi.packages/core';
import SelfExclusion from '@/components/modals/SelfExclusion';
import { routeName } from '@/router/const-name';
export default {
    name: 'AppErrorPage',
    data() {
        return {
            previousRoute: {},
            isPresto: deviceType.isPresto(),
        };
    },
    components: { SelfExclusion },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const { path } = from || {};
            const {
                meta: { backButtonPathName },
            } = to;
            if (path !== '/') {
                vm.previousRoute.path = path || '/';
            } else {
                vm.previousRoute.name = backButtonPathName || routeName.HOMEPAGE;
            }
        });
    },
    methods: {
        onClose() {
            if (this.isPresto) {
                this.$router.push(this.previousRoute);
            } else {
                this.$router.back();
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
