<template>
    <div class="landing-page">
        <section class="intro">
            <div class="column first">
                <img class="logo" src="@/assets/images/logo_color.png" alt="logo" />
                <span>
                    <p>{{ $t('ui.landingPage.betSmallWinBig') }}</p>
                </span>
            </div>
            <div class="column second"></div>
        </section>
        <div class="gallery">
            <h3>{{ $t('ui.landingPage.selectYourCountry') }}</h3>
            <div class="container">
                <a v-for="jurisdiction in jurisdictions" :key="jurisdiction.id" :href="getUrl(jurisdiction)" class="thumbnail">
                    <img :src="jurisdiction.countryFlag" :alt="jurisdiction.countryName" />
                    <h4>{{ jurisdiction.countryName }}</h4>
                </a>
            </div>
        </div>
        <footer>
            <img src="@/assets/images/logo_monochrome.png" alt="logo" />
            <div v-if="copyright">
                <DynamicComponent :key="'copyright-' + index" v-for="(item, index) in copyright.content" :data="item" :path="'content'" />
            </div>
        </footer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { config, helper } from '@agi.packages/core';
import { action, getter as platformGetter } from '@agi.packages/platform';
import { DynamicComponent } from '@agi.packages/platform/components';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';

const prodUrl = 'https://www.{{brandName}}';

export default {
    name: 'LandingPage',
    mixins: [PageMixin, SEOMixin],
    components: { DynamicComponent },
    computed: {
        ...mapGetters({
            contentSlots: platformGetter.GET_CONTENT_SLOTS,
            jurisdictions: platformGetter.GET_JURISDICTIONS,
        }),
        copyright() {
            return this.contentSlots['NUMBER_COLLECTION_FOOTER'];
        },
    },
    methods: {
        $i: helper.interpolate,
        getUrl(jurisdiction) {
            if (config.isProd()) {
                return helper.interpolate(prodUrl, { brandName: jurisdiction.brandName });
            }
            let host = location.hostname.split('.');
            host[0] = jurisdiction.countryCode;
            return '//' + host.join('.');
        },
    },
    created() {
        this.$store.dispatch(action.GET_ALL_JURISDICTIONS);
        this.$store.dispatch(action.GET_CONTENT, 'NUMBER_COLLECTION_FOOTER');
    },
};
</script>

<style scoped lang="scss">
.landing-page {
    background-color: #f4f5f0;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    .intro {
        background-color: #575755;
        background-image: url(../../assets/images/backgrounds/my-betpawa.jpg);
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        min-height: 20%;

        @media (max-width: 796px) and (min-width: 320px) {
            min-height: 32%;
        }

        @media (min-width: 796px) {
            min-height: 50%;
        }

        .column {
            flex: 1;

            &.first {
                padding: 20px;
                display: flex;
                flex-flow: column;
                width: 65%;

                @media (max-width: 796px) and (min-width: 320px) {
                    width: 55%;
                }

                img {
                    width: 100%;
                    min-width: 100%;
                    max-width: 120px;
                    @media (min-width: 320px) {
                        max-width: 160px;
                    }
                }

                span {
                    margin: 20px 0;
                    flex: 1;
                    display: flex;
                    align-items: center;

                    p {
                        color: white;
                        font-size: 18px;
                        font-weight: bold;
                        @media (max-width: 796px) and (min-width: 320px) {
                            font-size: 22px;
                        }
                        @media (min-width: 796px) {
                            font-size: 32px;
                        }
                    }
                }
            }

            &.second {
            }
        }
    }

    .gallery {
        flex: 1;

        h3 {
            text-align: center;
            color: #252a2d;
            margin-bottom: 0;
        }

        .container {
            display: inline-block;
            width: 100%;

            .thumbnail {
                width: 80%;
                margin-left: 10%;
                margin-right: 10%;
                margin-top: 15px;
                text-align: center;
                float: left;
                background-color: #fff;
                padding-bottom: 12px;
                padding-top: 15px;
                border-radius: $border-radius;
                outline: 0;
                display: block;
                text-decoration: none;

                @media (max-width: 796px) and (min-width: 320px) {
                    width: 46%;
                    margin-left: 2%;
                    margin-right: 2%;
                }

                @media (min-width: 796px) {
                    width: 23%;
                    margin-left: 1%;
                    margin-right: 1%;
                }

                img {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

    footer {
        padding: 20px 0 15px;
        text-align: center;
        color: #aaaeb0;
        font-size: 13px;
        margin: 0 10px;

        img {
            width: 100%;
            max-width: 90px;
        }
    }
}
</style>
