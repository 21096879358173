<template>
    <div>
        <div
            v-for="(option, index) in options"
            :key="index"
            @click="handleSelectItemClick(option)"
            class="select-item"
            :class="{ 'select-item-current': option.isCurrent }"
        >
            <SvgIcon v-if="option.iconId" class="icon icon-size-medium" :iconId="option.iconId" />
            {{ option.label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterBySport',
    props: {
        options: {
            type: Array[Object],
            required: true,
        },
    },
    methods: {
        handleSelectItemClick(item) {
            this.$emit('onClick', item);
        },
    },
};
</script>

<style lang="scss" scoped>
.select-item {
    padding: 8px 6px;
    cursor: pointer;
    display: flex;
    align-items: center;

    & + & {
        margin-top: 4px;
    }
    &-current {
        background-color: #d9edb2;
        border-radius: 4px;
    }

    .svg-icon {
        margin-right: 4px;
    }
}
</style>
