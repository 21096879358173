<template>
    <div class="bet-side-bar-wrapper">
        <BetslipHeader @onSelect="handleSelect" :tabs="tabs" />
        <Betslip v-show="currentTab === tabType.BETSLIP" />
        <MyBets v-if="currentTab === tabType.MY_BETS" :isPreview="true" />
    </div>
</template>

<script>
import Betslip from './Betslip.vue';
import BetslipHeader from './Betslip/BetslipHeader.vue';
import MyBets from './MyBets.vue';

const tabType = {
    BETSLIP: 'Betslip',
    MY_BETS: 'MyBets',
};

export default {
    name: 'BetSideBar',
    components: { BetslipHeader, Betslip, MyBets },
    data() {
        return {
            tabType: tabType,
            tabs: [
                { type: tabType.BETSLIP, text: this.$t('ui.betslip.dropdown.betslip'), icon: 'icon-statement' },
                { type: tabType.MY_BETS, text: this.$t('ui.betslip.dropdown.myBets'), icon: 'icon-mybets-menu' },
            ],
            currentTab: tabType.BETSLIP,
        };
    },
    methods: {
        handleSelect(option) {
            this.currentTab = option;
        },
    },
};
</script>

<style lang="scss" scoped>
.bet-side-bar-wrapper {
    @include all_but_mini {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
</style>
