<template>
    <div
        :class="[
            'event-filter',
            {
                open: activeFilterKey,
                'non-fullscreen-filter': activeFilter && !activeFilter.isFullscreen(),
                'filter-hidden': hide,
                'event-filter-has-selected': hasSelectedItems,
            },
        ]"
        v-click-outside:[true]="closeFilter"
    >
        <div class="event-filter-content">
            <div class="event-filter-head">
                <div
                    v-for="filter in filters"
                    :key="filter.key"
                    @click="changeFilter(filter)"
                    :data-test-id="`filters-${filter.key}`"
                    class="filter-item"
                    :class="{
                        'filter-item-grow': filter.text,
                        'filter-item-open': activeFilterKey === filter.key,
                    }"
                >
                    <div v-if="filter.iconId" class="icon-wrapper">
                        <SvgIcon class="icon icon-size-medium" :iconId="filter.iconId" />
                    </div>
                    <div v-if="filter.text" class="title">{{ filter.text }}</div>
                    <div class="state-icon-wrapper">
                        <SvgIcon
                            class="state-icon icon-size-very-small"
                            :iconId="activeFilterKey === filter.key ? 'icon-arrow-up' : 'arrow_down'"
                        />
                    </div>
                </div>
            </div>
            <div v-if="activeFilterKey" class="event-filter-options-list event-filter-options-list-mobile">
                <div
                    class="options-list"
                    :class="{
                        'options-list-without-padding': activeFilter && !activeFilter.hasPaddings(),
                        'options-list-mobile': !desktopBehavior,
                    }"
                >
                    <FilterByMarket
                        v-if="activeFilterKey === filterKeys.MARKET && marketGroups.length"
                        :options="marketGroups"
                        @onClick="handleMarketClick"
                    />
                    <FilterBySport
                        v-if="activeFilterKey === filterKeys.SPORT_TYPE"
                        :options="sportTypeList"
                        @onClick="handleSportTypeClick"
                    />
                    <FilterByTournament
                        v-if="activeFilterKey === filterKeys.TOURNAMENT"
                        :tournaments="tournaments"
                        :competitions="competitions"
                        @onAllCheck="onAllCompetitionsCheck"
                        @onCheck="onCompetitionCheck"
                    />
                </div>
                <div v-if="activeFilter.hasFooter()" class="event-filter-actions">
                    <button class="button button-accent" @click="resetFilters">{{ $t('ui.eventFilters.resetFilters') }}</button>
                    <button class="button button-primary" @click="applyFilters">{{ $t('ui.eventFilters.apply') }}</button>
                </div>
            </div>
        </div>
        <div class="event-filter-footer">
            <div class="event-filter-selected-list">
                <FilterSelectedItemList
                    :queryMarket="queryMarket"
                    @selectedItemsChanged="selectedItemsChanged"
                    @onRemove="removeCompetitions"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mutation, getter as generalGetter } from '@/store/store';
import { FilterSelectedItemList, FilterByMarket, FilterBySport, FilterByTournament } from '@agi.packages/sport/components';
import { helper } from '@agi.packages/core';
import { getter as platformGetter } from '@/modules/platform';

export default {
    name: 'EventFilter',
    components: {
        FilterSelectedItemList,
        FilterByMarket,
        FilterBySport,
        FilterByTournament,
    },
    props: {
        desktopBehavior: {
            type: Boolean,
            required: false,
            default: false,
        },
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            filterKeys: {
                SPORT_TYPE: 'sport-type',
                TOURNAMENT: 'tournament',
                MARKET: 'market',
            },
            activeFilter: null,
            marketTypeId: '',
            competitions: [],
            hasSelectedItems: false,
        };
    },
    computed: {
        ...mapState({
            tournaments: 'currentTournaments',
        }),
        ...mapGetters({
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            categories: generalGetter.GET_SORTED_CATEGORIES,
            marketTypeFilters: platformGetter.GET_MARKET_TYPE_FILTERS,
        }),
        filters() {
            return [
                {
                    iconId: this.$t(`project.sports.${this.categoryId}.icon`),
                    text: '',
                    key: this.filterKeys.SPORT_TYPE,
                    isFullscreen: () => false,
                    hasPaddings: () => true,
                    hasFooter: () => false,
                },
                {
                    iconId: '',
                    text: this.$t('ui.eventFilters.tournaments'),
                    key: this.filterKeys.TOURNAMENT,
                    isFullscreen: () => true,
                    hasPaddings: () => false,
                    hasFooter: () => true,
                },
                {
                    iconId: '',
                    text: this.$t('ui.eventFilters.markets'),
                    key: this.filterKeys.MARKET,
                    isFullscreen: () => this.$mq.isXMedium,
                    hasPaddings: () => false,
                    hasFooter: () => true,
                },
            ];
        },
        marketGroups() {
            const currentFilters = this.marketTypeFilters.find(({ category }) => String(category) === String(this.categoryId));
            return currentFilters
                ? currentFilters.filters
                      .map((filter, index) => ({
                          name: 'filter-market',
                          label: filter.name,
                          queryMarketId: filter.name,
                          id: filter.marketType,
                          isCurrent: this.marketTypeId === filter.marketType,
                          index,
                          priority: filter.priority,
                      }))
                      .sort((a, b) => a.priority - b.priority)
                : [];
        },
        sportTypeList() {
            return this.categories.map((category) => ({
                label: this.$t(`project.sports.${category.id}.text`),
                iconId: this.$t(`project.sports.${category.id}.icon`),
                id: category.id,
                isCurrent: String(category.id) === String(this.categoryId),
            }));
        },
        categoryId() {
            return this.$route.query?.categoryId || this.currentCategoryId;
        },
        queryMarket() {
            const { marketId } = this.$route.query;
            if (!marketId) {
                return this.marketGroups[0];
            }
            return (
                this.marketGroups.find(({ queryMarketId }) => {
                    return queryMarketId === marketId;
                }) || this.marketGroups[0]
            );
        },
        queryMarketId() {
            const { queryMarketId } =
                this.marketGroups.find(({ id }) => {
                    return String(id) === this.marketTypeId;
                }) || this.marketGroups[0];
            return queryMarketId;
        },
        isCompetitionsChanged() {
            const queryCompetitions = this.getCompetitionsFromUrl();
            const isSameCompetitions =
                this.competitions.length === queryCompetitions.length && this.competitions.every((i) => queryCompetitions.includes(i));

            return !isSameCompetitions;
        },
        activeFilterKey() {
            return this.activeFilter && this.activeFilter.key;
        },
    },
    methods: {
        selectedItemsChanged({ items }) {
            this.$emit('selectedItemsChanged', { items });
            this.hasSelectedItems = items.length > 0;
        },
        changeFilter(filter) {
            if (filter.key !== this.activeFilterKey) {
                this.activeFilter = filter;
            } else {
                this.closeFilter();
            }
        },
        handleMarketClick(item) {
            const { id } = item;
            this.marketTypeId = String(id);
        },
        handleSportTypeClick(item) {
            this.$router.push({ name: 'Upcoming', params: { categoryId: item.id } }).then(() => {
                this.$store.commit(mutation.SET_CURRENT_CATEGORY, item.id);
                window.location.reload();
            });
        },
        onAllCompetitionsCheck({ checked, competitions }) {
            const countryCompetitions = competitions.map((i) => i.id);
            if (checked) {
                this.competitions = helper.removeArrayDuplicates(this.competitions, countryCompetitions);
            } else {
                this.competitions = this.competitions.filter((i) => !countryCompetitions.includes(i));
            }
        },
        onCompetitionCheck({ checked, competition }) {
            const { id } = competition;
            if (checked) {
                this.competitions.push(id);
            } else {
                this.competitions = this.competitions.filter((i) => i !== id);
            }
        },
        removeCompetitions({ competitions, isMarket }) {
            if (isMarket) {
                this.handleMarketClick(this.marketGroups[0]);
            }
            if (competitions && competitions.length) {
                this.competitions = this.competitions.filter((i) => !competitions.includes(i));
            }
            this.applyFilters();
        },
        resetFilters() {
            const competitions = this.competitions;
            if (this.activeFilterKey === this.filterKeys.MARKET) {
                this.removeCompetitions({ isMarket: true });
            } else if (this.activeFilterKey === this.filterKeys.TOURNAMENT) {
                this.removeCompetitions({ competitions });
            }

            this.closeFilter();
        },
        isSamePathAndQuery({ name, query }) {
            const { name: currentName, query: currentQuery } = this.$route;
            const currentEntries = Object.entries(currentQuery || {});
            const newEntries = Object.entries(query || {});

            const isSameName = name === currentName;
            const isSameQuery = currentEntries.length === newEntries.length && currentEntries.every(([key, value]) => query[key] === value);

            return isSameName && isSameQuery;
        },
        applyFilters() {
            const { name, query } = this.$route;
            const newQuery = {
                ...query,
                marketId: this.queryMarketId,
            };
            if (this.competitions.length) {
                newQuery.competitions = this.competitions.join(',');
            } else {
                delete newQuery.competitions;
            }
            if (this.isCompetitionsChanged) {
                delete newQuery.id;
            }

            if (!this.isSamePathAndQuery({ name, query: newQuery })) {
                const routeConfig = { query: newQuery };
                if (this.isCompetitionsChanged) {
                    routeConfig.name = 'Upcoming';
                }

                this.$gtm.query({
                    event: 'event_filters_request',
                    competitions: newQuery.competitions || '',
                    marketId: this.marketTypeId,
                    categoryId: newQuery.categoryId,
                });
                this.$router.replace(routeConfig);
            }

            this.closeFilter();
        },
        closeFilter() {
            this.activeFilter = null;
        },
        getCompetitionsFromUrl() {
            const queryParams = new URLSearchParams(window.location.search);
            const competitions = queryParams.get('competitions');
            let payloadCompetitions = [];
            if (competitions) {
                payloadCompetitions = Array.isArray(competitions) ? competitions : competitions.split(',');
            }
            return payloadCompetitions;
        },
    },
    watch: {
        '$route.query': {
            immediate: true,
            deep: true,
            handler: function ({ marketId }) {
                if (marketId) {
                    const { id } =
                        this.marketGroups.find(({ queryMarketId }) => {
                            return queryMarketId === marketId;
                        }) || this.marketGroups[0];
                    this.marketTypeId = String(id);
                }
                this.competitions = this.getCompetitionsFromUrl();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
$filter-height: 40px;
$filter-height-with-selected: 93px;
.event-filter {
    position: relative;
    overflow: hidden;
    transition: transform 0.35s linear;
    height: $filter-height;

    @include maxonecolumn {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    }

    ::v-deep {
        & ~ .section-middle {
            @include maxonecolumn {
                transition: transform 0.3s linear;
                transform: translateY($filter-height);
            }
        }
    }

    &.filter-hidden {
        transition: transform 0.3s linear;
        transform: translateY(-100%);

        ::v-deep {
            & ~ .section-middle {
                @include maxonecolumn {
                    transform: translateY(0);
                }
            }
        }
    }

    &-has-selected {
        height: $filter-height-with-selected;

        ::v-deep {
            & ~ .section-middle {
                transition: transform 0.3s linear;
            }
        }

        &:not(.filter-hidden) {
            ::v-deep {
                & ~ .section-middle {
                    @include maxonecolumn {
                        transform: translateY($filter-height-with-selected);
                    }
                }
            }
        }
    }

    &-head {
        display: flex;
        flex: 0 0 40px;
        overflow: auto;
    }

    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &-actions {
        display: flex;
        padding: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: $white-bg;
    }

    &-options-list {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
        width: 100%;
        background-color: $white-bg;

        &-mobile {
            display: flex;
            flex-direction: column;
        }
    }

    &-selected-list {
        border-bottom: 1px solid #e6e7e2;
    }

    &.open {
        height: 100%;

        &:not(.non-fullscreen-filter) .event-filter-content {
            height: 100%;
        }

        .event-filter-head {
            width: 100%;
        }

        .event-filter-options-list {
            flex: 1 1 auto;
            overflow: auto;
            box-shadow: none;
        }

        .event-filter-actions {
            flex: 0 0 50px;
        }
    }

    &.non-fullscreen-filter {
        height: auto;
        overflow: visible;

        .event-filter-options-list {
            position: absolute;
            top: 100%;
            z-index: 1;
        }
    }
}

.filter-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f5f0;
    border: 1px solid #e6e7e2;
    padding: 10px 12px 10px 16px;
    cursor: pointer;
    line-height: 16px;

    @include oldschoolbasic {
        padding: 10px 8px;
    }

    @include maxoldschoolandmini {
        padding: 10px 8px;
    }

    &-grow {
        flex-grow: 1;
    }

    &-open {
        background-color: $white-bg;
        border-bottom-color: $white-bg;
    }
}

.state-icon-wrapper {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.options-list {
    padding: 10px 12px;
    overflow: auto;

    &-without-padding {
        padding: 0;
    }

    &-mobile {
        flex-grow: 1;
    }
    &::v-deep {
        .radio-item:first-child {
            border-top: none;
        }
    }
}

.title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
}

.icon {
    margin-right: 4px;
    display: inline-block;
}

.button {
    width: 50%;
    max-width: none;

    & + & {
        margin-left: 8px;
    }
}
</style>
