<template>
    <div class="event-page">
        <EventNetworkError v-if="error" :error="error" :isLive="isLive" />
        <Event v-else-if="event && !showSpinner" :marketId="marketId" :event="event" :isLive="isLive">
            <template #widget>
                <Tabs
                    v-if="isLiveStreamTabDisplayable"
                    class="live-stream-tabs"
                    :tabs="streamTabs"
                    :active="selectedTab"
                    @select="changeLiveStreamTab"
                >
                    <Tab :name="streamTabNames.sportWidget">
                        <component :is="widgetComponent" :matchId="matchId" :isLive="isLive" />
                    </Tab>
                    <Tab :name="streamTabNames.videoStream">
                        <VideoStream
                            :stream-data="streamData"
                            @closeBetpawaTv="returnToDefaultView"
                            @openStatsTab="changeLiveStreamTabByName(streamTabNames.sportWidget)"
                        />
                    </Tab>
                </Tabs>
                <component v-if="!isLiveStreamTabSelected" :is="widgetComponent" :matchId="matchId" :isLive="isLive" />
            </template>
        </Event>
        <Spinner :listen="spinnerTriggers" :visible="showSpinner" class="viewport-padding align-top" />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { helper, deviceType, getter as coreGetter } from '@agi.packages/core';
import { Spinner, Tabs, Tab } from '@agi.packages/core/components';
import { sport, marketTypeCategory, widgetType } from '@agi.packages/sport';
import { Event, EventNetworkError, SportRadar, SportRadarLabel, LiveVideoStreamLabel, GeniusSport } from '@agi.packages/sport/components';
import { getter as platformGetter, auth } from '@agi.packages/platform';
import { getEventWidget } from '@agi.packages/sport/utils/event/get-event-widget';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import VideoStream from '@/components/content/VideoStream';
import { routeName } from '@/router/const-name';

const EVENT_NOT_FOUND_CODE = 2001;
const STREAM_TAB_NAMES = {
    sportWidget: 'sportWidgetTab',
    videoStream: 'videoStreamTab',
};
export default {
    name: 'EventView',
    mixins: [PageMixin, SEOMixin],
    components: { VideoStream, Tabs, Tab, Event, Spinner, EventNetworkError, SportRadar, GeniusSport },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            streamTabNames: STREAM_TAB_NAMES,
            marketId: marketTypeCategory.ALL,
            matchId: null,
            spinnerTriggers: this.triggerActions,
            showSpinner: true,
            selectedTab: {},
            isLiveStreamTabSelected: false,
        };
    },
    computed: {
        ...mapState({
            errors: (state) => state.sport.sports.singleEvent.errors,
            isStatisticOpen: (state) => state.sport.isStatisticOpen,
            isLiveStreamAvailable: (state) => state.sport.isLiveStreamAvailable,
            userUuid: (state) => state.platform.settings.user.userUuid,
        }),
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            settings: platformGetter.GET_SETTINGS,
            country: platformGetter.GET_COUNTRY,
            dateOptions: platformGetter.GET_DATE_OPTIONS,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            isLoading: coreGetter.IS_LOADING,
        }),
        widgetComponent() {
            const { type: eventWidgetType } = getEventWidget(this.event);
            switch (eventWidgetType) {
                case widgetType.GENIUSSPORTS:
                    return GeniusSport;
                default:
                    return SportRadar;
            }
        },
        streamData() {
            const { id: livestreamUuid } = getEventWidget(this.event, {
                wantedWidgetType: widgetType.STAT_PERFORM_LIVE_STREAM,
                convertIdToNumber: false,
            });
            const { id: fixtureUuid } = getEventWidget(this.event, {
                wantedWidgetType: widgetType.STAT_PERFORM_FIXTURE_DATA,
                convertIdToNumber: false,
            });
            const { statsPerformPlayerUrl: playerUrl } = this.brandPreference;

            return {
                livestreamUuid,
                fixtureUuid,
                playerUrl,
                userUuid: this.userUuid,
                isLive: this.isLive,
                eventStartTime: this.event.startTime,
            };
        },
        isStreamEnabled() {
            const { isStatsPerformEnabled } = this.brandPreference;
            return isStatsPerformEnabled;
        },
        triggerActions() {
            return this.isLive ? [] : [sport.action.GET_EVENT_WITH_PRICES_BY_ID];
        },
        error() {
            return this.errors.find((event) => event.eventId === this.eventId && event.marketId === this.marketId);
        },
        event() {
            return this.$store.getters[sport.getter.GET_EVENT_WITH_MARKETS_BY_ID]({ id: this.eventId, market: this.marketId });
        },
        eventId() {
            return this.$route.params.id;
        },
        isLive() {
            return !!this.event?.additionalInfo.live;
        },
        streamTabs() {
            return [
                {
                    key: STREAM_TAB_NAMES.sportWidget,
                    text: 'Statistics',
                    componentName: SportRadarLabel,
                    props: { isLive: this.isLive },
                    metrika: {
                        event: 'statistics_tab_click',
                        isLiveEvent: this.isLive,
                        isAuthenticated: this.isAuthenticated,
                        jurisdiction: this.country,
                        pageUrl: this.$route.path,
                    },
                },
                {
                    key: STREAM_TAB_NAMES.videoStream,
                    text: 'betPawaTv',
                    componentName: LiveVideoStreamLabel,
                    props: { isLive: this.isLive },
                    metrika: {
                        event: 'betpawa_tv_tab_click',
                        isLiveEvent: this.isLive,
                        isAuthenticated: this.isAuthenticated,
                        jurisdiction: this.country,
                        pageUrl: this.$route.path,
                    },
                },
            ];
        },
        isLiveStreamTabDisplayable() {
            return (
                this.isLiveStreamAvailable &&
                !this.isPresto &&
                this.isStreamEnabled &&
                this.streamData.fixtureUuid &&
                this.streamData.livestreamUuid &&
                this.streamData.playerUrl
            );
        },
    },
    watch: {
        eventId: {
            immediate: true,
            handler(id) {
                if (id) {
                    this.showSpinner = true;
                    this.$store.commit(sport.mutation.RESET_SINGLE_EVENT_ERROR);
                    const { isSROpen } = this.brandPreference;
                    if (isSROpen && !this.isStatisticOpen) {
                        this.$store.commit(sport.mutation.TOGGLE_STATISTIC);
                    }
                    this.$store.dispatch(sport.action.GET_EVENT_WITH_PRICES_BY_ID, id).finally(() => {
                        this.showSpinner = false;
                    });
                }
            },
        },
        event(eventItem) {
            if (eventItem) {
                const { name, startTime, competition, region, category } = eventItem;
                const { id: widgetId } = getEventWidget(eventItem, { convertIdToNumber: false });
                const parsedWidgetId = widgetId && widgetId.replace(/[^0-9]/g, '');

                const { id: competitionId, name: competitionName } = competition || {};
                const { id: regionId, name: regionName } = region || {};
                const { name: categoryName } = category || {};
                const { eventId } = this.$route.query || {};

                this.setMatchId({ matchId: Number(eventId) || Number(parsedWidgetId) });
                this.setSEOTags(
                    {
                        meta: {
                            seo: {
                                template: 'event',
                            },
                        },
                    },
                    {
                        match: name,
                        time: helper.formatDateTime(startTime, { ...this.dateOptions, isUtc: true }),
                        league: competitionName,
                        leagueId: competitionId,
                        country: regionName,
                        countryId: regionId,
                        sport: categoryName,
                    }
                );
            }
        },
        error(e) {
            if (e && e.errorCode === EVENT_NOT_FOUND_CODE) {
                this.$router.push({ name: routeName.EVENT_NOT_FOUND });
            }
        },
    },
    methods: {
        ...mapMutations({
            toggleStatistic: sport.mutation.TOGGLE_STATISTIC,
            setIsLiveStreamAvailable: sport.mutation.SET_IS_LIVE_STREAM_AVAILABLE,
        }),
        setMatchId({ matchId }) {
            if (this.isLive && this.matchId) {
                return;
            }
            this.matchId = matchId;
        },
        changeLiveStreamTab(e) {
            this.selectedTab = e;
            if (!this.isLiveStreamTabSelected) this.isLiveStreamTabSelected = true;
            if (!this.isStatisticOpen) this.toggleStatistic();
        },
        returnToDefaultView() {
            this.selectedTab = {};
            if (this.isLiveStreamTabSelected) this.isLiveStreamTabSelected = false;
            if (this.isStatisticOpen) this.toggleStatistic();
        },
        changeLiveStreamTabByName(tabName) {
            const tab = this.streamTabs.find((tab) => tab.key === tabName);
            this.changeLiveStreamTab(tab);
        },
    },
    destroyed() {
        this.setIsLiveStreamAvailable(false);
    },
};
</script>

<style lang="scss">
.disclaimer {
    padding: 12px;
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    background: $event-widget-dark-bg;
    color: $event-widget-dark-bg-disclaimer-text-color;
    border-top: 1px solid #353b40;

    @include oldschool {
        font-size: 18px;
        line-height: 21px;
    }

    &-light {
        border-top: 1px solid #e6e7e2;
        background: $event-widget-light-bg;
        color: $event-widget-light-bg-text;
    }
}

.live-stream-tabs.tabs {
    .tabs-menu {
        .tabs-selector {
            color: $light-grey-bg;
            background-color: $event-widget-dark-bg;
            border-color: $brand-secondary-lighten-1;
            padding: 8px;
            border-top: none;

            &.active {
                background-color: $event-widget-dark-bg;
                color: $live-stream-tab-active-color;
                border-bottom: 2px solid $live-stream-tab-active-color;

                svg:not(.tv-icon-dark):not(.tv-icon-light) {
                    fill: $live-stream-tab-active-color;
                }
            }

            .tab-label-text {
                margin-right: 4px;
            }

            .icon-stats {
                position: relative;
                top: -2px;
            }
        }
    }
}
</style>
