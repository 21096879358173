<template>
    <div class="jackpot-page">
        <ResponsiveImage v-if="!isAvailable" class="no-jackpots" :background="backgroundImages">
            <h4>{{ $t('ui.jackpot.error.notAvailable') }}</h4>
            {{ $t('ui.jackpot.error.checkLater') }}
        </ResponsiveImage>
        <template v-else-if="!ticket">
            <Tabs :tabs="tabs" :active="currentTab" @select="setCurrentTab">
                <Spinner slot="content" :listen="spinnerTriggers" :visible="!currentTab" />
                <Tab :name="tabType.ACTIVE">
                    <div class="jackpot-list-wrapper active" v-if="activeJackpots.length > 1">
                        <template v-if="currentTabContent && !jackpotPool">
                            <DynamicComponent
                                class="jackpot-top-content"
                                v-for="(item, index) in currentTabContent"
                                :key="'jp-active-banner-' + index"
                                :data="item"
                                :path="'content'"
                            />
                        </template>
                        <Jackpot
                            :key="jackpot.id + '-' + jackpot.name"
                            v-for="jackpot in activeJackpots"
                            :jackpot="jackpot"
                            class="jackpot-item"
                        >
                            <template slot="cta">
                                <a class="button-accent button button-full" @click="openJackpot(findTab(tabType.ACTIVE), jackpot)">
                                    {{ $t('ui.jackpot.playFor') }}
                                    <Currency
                                        class="value"
                                        :style="{ display: !$mq.isSmallest ? 'inline' : null }"
                                        :amount="jackpot.fee"
                                        :format="currencyFormat"
                                    />
                                </a>
                            </template>
                        </Jackpot>
                    </div>
                    <EventPool v-else-if="jackpotPool" :pool="jackpotPool" @ticket="ticket = $event" />
                    <ResponsiveImage v-else class="no-jackpots" :background="backgroundImages">
                        <template v-if="jackpot.error">
                            <h4>{{ jackpot.error }}</h4>
                        </template>
                        <template v-else-if="activeJackpots.length <= 0">
                            <h4>{{ $t('ui.jackpot.error.noActiveJackpots') }}</h4>
                        </template>
                        {{ $t('ui.jackpot.error.checkLater') }}
                    </ResponsiveImage>
                </Tab>
                <Tab :name="tabType.RESULTS">
                    <template v-if="currentTabContent && jackpotResults.length > 1 && !jackpotPool">
                        <DynamicComponent
                            class="jackpot-top-content"
                            v-for="(item, index) in currentTabContent"
                            :key="'jp-results-banner-' + index"
                            :data="item"
                            :path="'content'"
                        />
                    </template>
                    <div class="jackpot-list-wrapper results" v-if="jackpotResults.length > 1">
                        <Jackpot
                            v-for="jackpot in jackpotResults"
                            :key="jackpot.id + '-' + jackpot.name"
                            :jackpot="jackpot"
                            @open="openJackpot(findTab(tabType.RESULTS), $event)"
                            :button="{ text: $t('ui.jackpot.seeResults') }"
                            jackpotStatus="closed"
                            class="jackpot-item"
                        />
                    </div>
                    <TableSlip v-else-if="jackpotPool" :pool="jackpotPool"></TableSlip>
                    <ResponsiveImage v-else class="no-jackpots" :background="backgroundImages">
                        <template v-if="jackpot.error">
                            <h4>{{ jackpot.error }}</h4>
                        </template>
                        <template v-else>
                            <h4>{{ (jackpotPool && jackpotPool.error) || $t('ui.jackpot.error.noJackpots') }}</h4>
                        </template>
                        {{ $t('ui.jackpot.error.checkLater') }}
                    </ResponsiveImage>
                </Tab>
                <Tab :name="tabType.RULES">
                    <div v-if="currentTabContent" class="rules">
                        <DynamicComponent v-for="(item, index) in currentTabContent" :key="index" :data="item" :path="'content'" />
                    </div>
                    <div v-if="contentError" class="notify error">
                        <renderer :input="contentError" />
                    </div>
                </Tab>
            </Tabs>
        </template>
        <template v-else>
            <div class="ticket-success">
                <renderer class="notify success" :input="$t('ui.jackpot.congratulations', { ticketId: ticket.id })" />
                <span>
                    <div class="button button-primary button-full" @click="buyAnother">{{ $t('ui.jackpot.buyAnotherTicket') }}</div>
                    <router-link class="underline" :to="{ path: '/' }">{{ $t('ui.jackpot.orTrySportsBetting') }}</router-link>
                </span>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { sport, jackpotStatus, jackpotRequestType as requestType } from '@agi.packages/sport';
import { Tab, Tabs, Currency } from '@agi.packages/core/components';
import { action as generalAction, getter as generalGetter } from '@agi.packages/platform';
import { DynamicComponent } from '@agi.packages/platform/components';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import ResponsiveImage from '@/components/ResponsiveImage';
import { JACKPOT_BACKGROUNDS } from '@/components/content/content-const';

import TableSlip from '../TableSlip';
import Jackpot from '../Jackpot.vue';
import EventPool from '../EventPool';
import { routeName } from '@/router/const-name';
import { helper } from '@agi.packages/core';

const RULES_SLOT_ID = 'JACKPOT_RULES';
const ACTIVE_JP_BANNER = 'ACTIVE_JACKPOT_PAGE';
const RESULTS_JP_BANNER = 'RESULTS_JACKPOT_PAGE';

const tabType = {
    ACTIVE: 'active',
    RESULTS: 'results',
    RULES: 'rules',
};

export default {
    name: 'JackpotPage',
    mixins: [PageMixin, SEOMixin],
    components: {
        Tab,
        Tabs,
        Jackpot,
        EventPool,
        TableSlip,
        DynamicComponent,
        ResponsiveImage,
        Currency,
    },
    data: function () {
        return {
            tabType,
            currentTab: {},
            ticket: null,
            spinnerTriggers: [sport.action.GET_JACKPOTS, sport.action.GET_JACKPOT_POOL, generalAction.GET_CONTENT],
            backgroundImages: JACKPOT_BACKGROUNDS,
            skipJackpotLoad: false,
        };
    },
    computed: {
        ...mapState({
            activeJackpots: (state) => {
                const list = [...state.sport.jackpot.active] || [];
                const sortBy = 'priorityOrder';
                const byPriority = list.filter((it) => it[sortBy] !== null).sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : -1));
                const byDate = list
                    .filter((it) => it[sortBy] === null)
                    .sort((a, b) => (new Date(a.closeTime).getTime() > new Date(b.closeTime).getTime() ? 1 : -1));
                return [...byPriority, ...byDate];
            },
            jackpotResults: (state) => state.sport.jackpot.resulted || [],
            jackpot: (state) => state.sport.jackpot,
            contentError: (state) => state.platform.content.error.message,
            pool: (state) => state.platform.settings.pool,
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
        ...mapGetters({
            jackpotPool: sport.getter.GET_JACKPOT_POOL,
            getContentSlots: generalGetter.GET_CONTENT_SLOTS,
        }),
        currentTabContent() {
            const { slotId } = this.currentTab || {};
            const slot = this.getContentSlots[slotId];
            return slot && slot.content;
        },
        tabs() {
            return [
                { text: this.$t('ui.jackpot.tabs.active.name'), key: tabType.ACTIVE, status: 'active', slotId: ACTIVE_JP_BANNER },
                { text: this.$t('ui.jackpot.tabs.results.name'), key: tabType.RESULTS, status: 'closed', slotId: RESULTS_JP_BANNER },
                { text: this.$t('ui.jackpot.tabs.rules.name'), key: tabType.RULES, slotId: RULES_SLOT_ID },
            ];
        },
        isAvailable() {
            return !!this.pool?.enabled;
        },
    },
    methods: {
        findTab(key) {
            return this.tabs.find((tab) => tab.key === key);
        },
        openJackpot(tab, jackpot) {
            this.$router.push({
                name: routeName.JACKPOT_PAGE,
                query: {
                    tab: tab.key,
                    jackpotId: jackpot.id,
                },
            });
            this.$scroll.scrollTo(0, 1);
        },
        setCurrentTab(tab, callback) {
            (this.currentTab.key !== tab.key || this.$route.query.jackpotId) &&
                this.$router.push(
                    {
                        name: routeName.JACKPOT_PAGE,
                        query: {
                            tab: tab.key,
                        },
                    },
                    callback
                );
        },
        buyAnother() {
            this.$store.dispatch(sport.action.GET_JACKPOT_POOL, this.$route.query.jackpotId || this.jackpot.pool.id);
            this.ticket = null;
        },
        onRouteChange(query) {
            const tabTypes = this.tabs.map((tab) => tab.key);
            this.currentTab = this.tabs.find((tab) => tab.key === query.tab);
            this.ticket = null;
            if (query.tab === tabType.RULES) {
                this.$store.dispatch(generalAction.GET_CONTENT, RULES_SLOT_ID);
            } else if (query.jackpotId) {
                this.$store.dispatch(sport.action.GET_JACKPOT_POOL, query.jackpotId).then(() => {
                    const { name, fee, prizeTotal } = this.jackpotPool;
                    this.setSEOTags(
                        {
                            meta: {
                                seo: { template: 'jackpotDetails' },
                            },
                        },
                        {
                            jackpotName: name,
                            jackpotPrice: fee,
                            jackpotTotal: prizeTotal,
                        }
                    );
                });
            } else if ((tabTypes.includes(query.tab) && !this.skipJackpotLoad) || !query.tab) {
                this.$store.dispatch(sport.action.GET_JACKPOTS, {
                    requestType: (query.tab && requestType[query.tab.toUpperCase()]) || requestType.ACTIVE,
                    loadResults: !query.tab,
                });
            }
        },
    },
    watch: {
        $route(to) {
            this.onRouteChange(to.query);
            this.skipJackpotLoad = false;
        },
        jackpot({ pool, active, resulted }) {
            const routeQuery = this.$route.query || {};
            const onlyOneActiveJackpot = helper.getObjectField(active, 'length') === 1;
            const selectedJackpot = pool;
            if ((selectedJackpot || onlyOneActiveJackpot) && !routeQuery.jackpotId) {
                this.$router.replace({
                    name: routeName.JACKPOT_PAGE,
                    query: { ...routeQuery, tab: tabType.ACTIVE, jackpotId: helper.getObjectField(selectedJackpot, 'id') || active[0].id },
                });
            } else if (
                selectedJackpot &&
                selectedJackpot.status === jackpotStatus.CLOSED &&
                this.currentTab.key === tabType.ACTIVE &&
                !routeQuery.jackpotId
            ) {
                this.skipJackpotLoad = true;
                this.$router.replace({
                    name: routeName.JACKPOT_PAGE,
                    query: { ...routeQuery, tab: tabType.RESULTS, jackpotId: selectedJackpot.id },
                });
            } else if (!routeQuery.tab) {
                const tab = active.length > 0 || resulted.length <= 0 ? tabType.ACTIVE : tabType.RESULTS;
                this.skipJackpotLoad = true;
                this.$router.replace({
                    name: routeName.JACKPOT_PAGE,
                    query: { ...routeQuery, tab },
                });
            }
        },
    },
    created() {
        this.onRouteChange(this.$route.query);
    },
    beforeDestroy() {
        this.$store.commit(sport.mutation.RESET_JACKPOT_ERROR);
    },
};
</script>

<style scoped lang="scss">
.tax-enabled {
    padding: 8px;
}

.jackpot-list-wrapper {
    padding: 8px 8px;

    .jackpot {
        margin: 0 0 8px;
    }

    &.active {
        .jackpot-top-content + .jackpot-item {
            border: 8px solid $jackpot-page-top-content-color;
            margin: -16px -8px 8px -8px;
        }

        .jackpot-top-content {
            border: 8px solid $jackpot-page-top-content-color;
            margin: -8px;
            margin-top: 0;
            background: $jackpot-page-top-content-color;
        }
    }
}

.ticket-success {
    padding: 20px;

    span {
        padding: 20px;
        display: block;
        text-align: center;

        .button {
            margin-bottom: 20px;
        }
    }
}

.rules {
    margin: 0 20px;
}

.no-jackpots {
    font-size: 13px;
    text-align: center;
    color: $jackpot-page-no-jackpots-color;
    padding: 40px 0 60px;

    h4 {
        font-size: 18px;
        margin-bottom: 0.2em;
    }
}
</style>
