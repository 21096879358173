<template>
    <div v-if="eventsError" v-html="eventsError" class="page-error"></div>
    <div v-else class="popular-main">
        <Spinner :listen="spinnerTrigger" />
        <div v-if="!events.length" class="page-error-text">{{ $t('ui.popular.noActiveEvents') }}</div>
        <Game :key="event.ExId" v-for="event in events" :event="event" :showMarketCount="true" />
        <HotDescription v-if="containsHot" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { sport, EventType, DEFAULT_TAKE, EventCategory } from '@agi.packages/sport';
import { buildEventsQueryParams } from '@agi.packages/sport/utils/api/build-events-query-params';
import { getter as generalGetter } from '@/store/store';

import HotDescription from '@/components/HotDescription';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';

import Game from '../Game.vue';

export default {
    name: 'PopularView',
    mixins: [PageMixin, SEOMixin],
    data: () => ({
        spinnerTrigger: sport.action.GET_EVENTS,
    }),
    components: { Game, HotDescription },
    computed: {
        ...mapState({
            eventsError: (state) => state.sport.sports.error,
            containsHot: (state) => state.sport.sports.containsHot,
        }),
        ...mapGetters({
            events: sport.getter.GET_POPULAR_EVENTS,
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
        }),
    },
    created: function () {
        this.$store.dispatch(sport.action.RESET_SPORTS_ERROR);
        if (!this.$route.meta?.scrollSelector) {
            this.$store.dispatch(sport.action.GET_EVENTS, {
                rawQuery: buildEventsQueryParams({
                    categories: [this.currentCategoryId],
                    eventType: EventType.UPCOMING,
                    take: DEFAULT_TAKE,
                    onlyPopular: true,
                    hasOdds: true,
                }),
                eventCategory: EventCategory.POPULAR,
            });
        }
    },
};
</script>

<style scoped lang="scss">
.page-error-text {
    padding-top: 24px;
    text-align: center;
}
</style>
