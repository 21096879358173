<template>
    <div :class="['messages', $mq.isXXMediumMin && 'page']">
        <h2 class="messages-title">
            {{ $t('ui.messaging.messages') }}
            <Badge v-show="newMessagesNumber" :text="newMessagesNumber.toString()" type="new-messages" />
        </h2>
        <div v-if="messages.length">
            <LinkWrapper
                v-for="message in messages"
                :key="message.id + message.startTime"
                :class="['message', 'table', isNewMessage(message) ? 'new' : 'expired']"
                :link="message.ctaLink"
                :noWrap="!!(message.ctaLink && message.ctaText && message.ctaText.trim().length > 0) || message.expired"
                @clicked="markAsClicked(message, ...arguments)"
            >
                <span class="row-cell message-icon">
                    <SvgIcon
                        v-if="hasIcon(message)"
                        :iconId="getMessageIcon(message)"
                        class="icon-message"
                        :class="!isNewMessage(message) && 'icon-disabled'"
                    />
                </span>
                <div class="message-content row-cell">
                    <div class="message-text">{{ message.messageText }}</div>
                    <div class="message-status">
                        <span>{{ formatDateTime(message.startTime) }}</span>
                        <span v-if="message.expired" class="expired-label">
                            {{ $t('ui.messaging.expired') }}
                        </span>
                        <LinkWrapper
                            v-else-if="message.ctaText"
                            :link="message.ctaLink"
                            class="cta-text underline"
                            @clicked="markAsClicked(message)"
                        >
                            {{ message.ctaText }}
                        </LinkWrapper>
                    </div>
                </div>
            </LinkWrapper>
            <div v-if="!loadingMessages && currentPage + 1 < totalPages" class="show-more">
                <span @click="loadMoreMessages()" class="button button-accent">
                    {{ $t('ui.messaging.showMore') }}
                </span>
            </div>
        </div>
        <div v-else-if="error && error.message" class="notify error">
            <renderer :input="error.message" />
        </div>
        <div v-else-if="!loadingMessages" class="no-messages">{{ $t('ui.messaging.noMessages') }}</div>
        <Spinner :visible="loadingMessages" class="align-center inset viewport-padding" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { helper, getter as coreGetter } from '@agi.packages/core';
import { Spinner } from '@agi.packages/core/components';
import { messaging, MESSAGE_STATUS, MESSAGE_ICONS_MAP, getter as platformGetter } from '@agi.packages/platform';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';

import LinkWrapper from '../LinkWrapper';

const Badge = () => import('@/components/Badge');

export default {
    name: 'Messages',
    mixins: [PageMixin, SEOMixin],
    components: { Badge, Spinner, LinkWrapper },
    computed: {
        ...mapState({
            messages: (state) => state.platform.messaging.messages,
            newMessagesNumber: (state) => state.platform.messaging.notSeen,
            currentPage: (state) => state.platform.messaging.currentPage,
            totalPages: (state) => state.platform.messaging.totalPages,
            error: (state) => state.platform.messaging.error,
        }),
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            isLoading: coreGetter.IS_LOADING,
        }),
        loadingMessages() {
            return this.isLoading(messaging.action.GET_MESSAGES);
        },
    },
    created() {
        this.$store.dispatch(messaging.action.GET_MESSAGES);
    },
    methods: {
        getMessageIcon(message) {
            return MESSAGE_ICONS_MAP[message.messageType];
        },
        isNewMessage(message) {
            return message.messageStatus === MESSAGE_STATUS.delivered && !message.expired;
        },
        formatDateTime(timestamp) {
            const { day, date, month, time } = helper.formatDateTime(timestamp, {
                toObject: true,
                ...this.dateOptions,
            });
            return `${day} ${date}/${month} - ${time}`;
        },
        loadMoreMessages() {
            this.$store.dispatch(messaging.action.GET_MESSAGES, this.currentPage + 1);
        },
        markAsClicked(message) {
            if (message.messageStatus === MESSAGE_STATUS.clicked) {
                return;
            }
            this.$store.dispatch(messaging.action.MARK_AS_CLICKED, message.id);
        },
        hasIcon(message) {
            if (!message && !message.messageType) {
                return false;
            }
            return Object.keys(MESSAGE_ICONS_MAP).includes(message.messageType);
        },
    },
};
</script>
<style scoped lang="scss">
.messages {
    &-title {
        @include maxonecolumn {
            margin: 16px 8px;
            text-align: $messaging-title-align;
        }

        .badge {
            vertical-align: middle;
            padding: 4px 6px;
            margin: 0;
            font-size: 12px;
            line-height: 10px;
        }
    }

    .no-messages {
        @include maxonecolumn {
            margin-left: 8px;
        }
    }

    .message {
        width: 100%;
        padding: 12px 8px;
        border-bottom: 1px solid $messaging-message-border-color;

        &:first-child {
            border-top: 1px solid $messaging-message-border-color;
        }

        &.new {
            background-color: $messaging-new-message-background-color;
            border-bottom: 2px solid $messaging-new-message-border-color;
            border-top: 0;
        }

        &.expired {
            color: $disabled-text;
        }

        &-content {
            width: 100%;
            vertical-align: top;
        }

        svg {
            margin-left: 8px;
            width: 27px;
            height: 27px;
        }

        .message-icon {
            min-width: 48px;
        }

        &-text {
            @extend %body-normal-font-700;
            word-break: break-word;
        }

        &-status {
            @extend %small-details-font-400;

            .expired-label,
            .cta-text {
                float: right;
                font-weight: bold;
            }
        }
    }

    .show-more {
        margin-top: 10px;
        text-align: center;

        @include maxonecolumn {
            margin-bottom: 18px;
        }
    }
}
</style>
