<template>
    <div class="loading-element">
        <SvgIcon iconId="logo-black" iconFallback="logo-black" class="logo-black" />
        <div v-if="!hideProgressBar" class="loading-element-progress">
            <div class="loading-element-progress-bar"></div>
        </div>
        <div class="loading-element-text" v-else>{{ text || $t('ui.common.loading') }}</div>
    </div>
</template>

<script>
export default {
    name: 'LoadingElementCore',
    props: {
        text: {
            type: String,
            default: '',
        },
        hideProgressBar: {
            type: Boolean,
        },
    },
};
</script>

<style lang="scss">
@keyframes progress_bar {
    0% {
        left: -150%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 5%;
    }
}

.loading-element {
    display: flex;
    align-items: center;
    background: $body-background-color;
    justify-content: center;
    position: relative;
    flex-direction: column;

    &-text {
        font-size: 18px;
        margin-top: 24px;
        text-align: center;
    }

    .logo-black {
        width: 208px;

        @include oldschool {
            width: 154px;
        }
    }

    &-progress {
        width: 100%;
        height: 4px;
        overflow: hidden;
        background-color: $light-grey;
        margin-top: 40px;

        &-bar {
            position: relative;
            width: 100%;
            height: 100%;

            &:after {
                content: '';
                position: absolute;
                height: 100%;
                background-color: $green;
                animation: progress_bar 1s infinite cubic-bezier(0.71, 0, 0.28, 1);
            }
        }
    }
}
</style>
