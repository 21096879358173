<template>
    <div class="tab-label">
        <span class="tab-label-text">{{ $t('ui.common.statistics') }}</span>
        <SvgIcon :iconId="`icon-${isLive ? 'stadium' : 'statistics'}`" class="icon-statistics tab-label-icon icon-stats" />
    </div>
</template>

<script>
export default {
    name: 'SportRadarLabel',
    props: {
        isLive: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
