<template>
    <LoadingElementCore :text="text" :hideProgressBar="isPresto" />
</template>

<script>
import { deviceType } from '@agi.packages/core';
import LoadingElementCore from '@/components/Fragments/LoadingElementCore.vue';

export default {
    name: 'LoadingElement',
    components: { LoadingElementCore },
    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
};
</script>
