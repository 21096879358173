<template>
    <div class="TOC">
        <ol>
            <li v-for="item in content" :key="item.id">
                <h2>
                    {{ item.id.split('-')[2] }}.
                    <a class="underline" :id="item.id + '-toc'" :href="'#' + item.id">
                        {{ item.text }}
                    </a>
                </h2>
                <ol>
                    <li v-for="subItem in item.subContent" :key="subItem.id">
                        <h3>
                            {{ subItem.id.split('-')[2] }}.{{ subItem.id.split('-')[3] }}
                            <a class="underline" :id="subItem.id + '-toc'" :href="'#' + subItem.id">
                                {{ subItem.text }}
                            </a>
                        </h3>
                    </li>
                </ol>
            </li>
        </ol>
    </div>
</template>

<script>
export default {
    name: 'TOC',
    props: {
        content: {
            type: Array[Object],
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.TOC {
    h2,
    h3 {
        margin: 0;
    }

    h3 {
        @extend %body-big-font-700;
    }

    ol ol {
        margin-left: 25px;
    }

    li {
        margin: 6px 0;
        list-style: none;
    }
}
</style>
