<template>
    <div>
        <span class="label-text">{{ labelText }} <slot name="icon"></slot></span>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'LabelWithContent',
    props: {
        labelText: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.label-text {
    margin-right: 5px;
    @extend %body-normal-font-400;
}
</style>
