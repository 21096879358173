<template>
    <div class="page-headline" :class="{ category: isCategory }">
        <h1 class="page-headline-title" :class="{ pointer: isClickable, 'page-headline-title-small': isSmallFont }" @click="onClick">
            <SvgIcon v-if="icon" :icon-id="icon" class="page-headline-icon" />
            <slot>{{ title }}</slot>
            <slot name="subheading" />
        </h1>
        <span v-if="info" @click="$emit('open-info', info)" class="page-headline-info">
            <SvgIcon class="page-headline-info-icon" icon-id="icon-info" />
        </span>
    </div>
</template>

<script>
export default {
    name: 'Headline',
    props: {
        title: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        info: {
            type: [Boolean, String],
            default: '',
        },
        isClickable: {
            default: false,
        },
        isCategory: {
            default: false,
        },
        isSmallFont: {
            default: false,
        },
    },
    methods: {
        onClick() {
            if (this.isClickable) {
                this.$emit('open-sport');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.page-headline {
    position: relative;
    padding: 10px;
    white-space: nowrap;
    border-bottom: $headline-page-headline-border-bottom-width solid $headline-page-headline-border-bottom-color;
    background: $headline-page-headline-background;
    @include maxonecolumn {
        text-align: center;
        text-transform: $headline-transform;
    }
    &.category {
        background: $light-grey-bg;
    }

    .page-headline-info {
        position: absolute;
        right: 10px;
        cursor: pointer;
    }

    .page-headline-title {
        @extend %h2-font-700;

        position: relative;
        display: inline-block;
        vertical-align: middle;
        word-break: break-word;
        margin: 0;
        white-space: normal;
        padding-left: 34px;
        padding-right: 18px;
        text-align: left;

        &.category {
            background: pink;
        }
    }
    .page-headline-title-small {
        @extend %h4-font-700;
        padding-left: 0;
        padding-right: 0;
    }

    svg.page-headline-icon {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        width: 24px;
        height: 24px;
        fill: $icon-primary;
    }

    svg.page-headline-info-icon {
        width: 18px;
        height: 18px;
    }
}
</style>
