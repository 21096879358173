import Vue from 'vue';
import config from '../api/config';
import deviceType, { deviceTypesArray } from '../deviceType';
import deepMerge from './deepMerge';
import { routeName as routeNames } from '@/router/const-name';
import { durationUnitsType, convertToDurationUnits, getDurationUnitsCutoffBy, getUnsupportedUnitError } from './duration';

const interpolateRegex = /{{(.*?)}}/g;
const numberCompactMapper = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
];

const helper = {
    replaceSpaces: function (str) {
        return str.replace(/\s+/g, '-');
    },
    getRegionCode: function (string) {
        const regex = /(\d+)/;
        const result = regex.exec(string);
        return result ? result[1] : undefined;
    },
    hasInterpolateParams(str, regex = interpolateRegex) {
        if (!str || typeof str !== 'string') {
            return false;
        }
        return regex.test(str);
    },
    /**
     * Substitute properties in a string with their value.
     * Example:
     *    var msg = _.interpolate('Hello, {{name}}!', {name: 'Jos'});
     * @param {string} str - String with interpolated vars
     * @param {Object} keys - The author of the book.
     * @optional {Object} regex - The author of the book.
     * @return {String} interpolated string
     */
    interpolate(str, props, regex = interpolateRegex) {
        if (!str) {
            console.warn('String param is ' + str);
            return str;
        }
        const replacedStr = str.replace(regex, (original, k) => {
            if (props[k] !== undefined) {
                return props[k.trim()];
            }
            return `[${k}]`;
        });
        let result = replacedStr;
        if (helper.hasInterpolateParams(replacedStr, regex)) {
            result = helper.interpolate(replacedStr, props, regex);
        }
        return result;
    },
    numberCompact(number, decimals = 2, skipDecimals = 2, skips = []) {
        const compactMapper = numberCompactMapper.slice().reverse();
        const compactFinder = (it) => {
            if (number < it.value) return false;
            for (const skip of skips) {
                if (typeof skip !== 'number') continue;
                if (number >= skip && number < skip * 1e1) return false;
            }
            return true;
        };
        const compactItem = compactMapper.find(compactFinder);

        if (!compactItem || !compactItem.symbol.length) {
            return helper.numberFormat(helper.rounded(number, skipDecimals, Math.round));
        }
        return helper.rounded(number / compactItem.value, decimals, Math.round) + compactItem.symbol;
    },
    rounded(value, decimals = 2, fn = Math.floor) {
        const decs = Math.pow(10, decimals);
        const fixedPoint = Number((value * decs).toFixed(decimals));
        return fn(fixedPoint) / decs;
    },
    /**
     * @deprecated since v1.34.0 - use `formatDateTime()` instead.
     */
    getZonedDate(dateTime, timeZoneOffset) {
        if (deviceType.isPresto()) {
            const tmpDate = new Date(dateTime);
            const currentTimezoneOffset = Math.abs(tmpDate.getTimezoneOffset() * 60000);
            const timezoneOffsetDifference = timeZoneOffset - currentTimezoneOffset;
            return new Date(tmpDate.getTime() + timezoneOffsetDifference);
        } else {
            return new Date(new Date(dateTime).getTime() + timeZoneOffset);
        }
    },
    /**
     *  Convert minutes to w weeks, x days, y hours, z minutes
     *  includes translations
     *  @param {number|string} value - minutes
     *  @param {object} [options] - options
     *  @param {string} [options.delimiter] - delimiter between values
     *  @param {string} [options.biggestUnit] - biggest unit that can be in value
     *  @param {number} [options.maxUnits] - max count of units
     *  @param {boolean} [options.isNumberOneVisible] - render day instead 1 day
     *  @returns {string}
     */
    formatMinutesToDHM(value, options = {}) {
        const minutes = Number(value);
        if (!minutes) return '';

        const { delimiter = ' ', biggestUnit, maxUnits, isNumberOneVisible = true } = options;
        const units = getDurationUnitsCutoffBy({ biggestUnit, lowestUnit: durationUnitsType.MINUTE });
        const durations = convertToDurationUnits(minutes, durationUnitsType.MINUTE, units);

        const result = [];
        for (const { value, unit } of durations) {
            value && result.push(this.renderUnitDurationText(value, unit, !isNumberOneVisible && value === 1 && maxUnits === 1));
        }
        return result.slice(0, maxUnits).join(delimiter);
    },
    /**
     *  Get translated text for duration
     *  @param {number} duration - duration value
     *  @param {string} unit - unit type value
     *  @param {boolean} isNumberOneVisible - show/hide digit `1` in duration text
     *  @returns {string}
     */
    renderUnitDurationText(duration, unit, isNumberOneVisible = true) {
        if (duration > 0) {
            const key = unit + (duration > 1 ? 's' : '');
            const value = !isNumberOneVisible && duration === 1 ? '' : duration;
            return Vue.$t(key, { value });
        }
        return '';
    },
    /**
     *  Add duration time to date
     *  @param {object} [options] - params
     *  @param {Date} [options.date] - date value
     *  @param {string} [options.unit] - unit type value
     *  @param {number} [options.value] - duration value
     *  @returns {Date}
     */
    addDurationToDate({ date, unit, value }) {
        const getParamErrorMessage = (param, value, expected) => `Invalid ${param} type, expected ${expected}, received ${typeof value}`;
        if (!(date instanceof Date)) {
            throw new Error(getParamErrorMessage('date', date, 'Date'));
        }
        if (!Number.isInteger(value)) {
            throw new Error(getParamErrorMessage('value', value, 'integer'));
        }

        const dateCopy = new Date(date);
        switch (unit) {
            case durationUnitsType.DAY:
                dateCopy.setDate(dateCopy.getDate() + value);
                break;
            case durationUnitsType.WEEK:
                dateCopy.setDate(dateCopy.getDate() + value * 7);
                break;
            case durationUnitsType.MONTH:
                dateCopy.setMonth(dateCopy.getMonth() + value);
                break;
            default:
                throw getUnsupportedUnitError(unit);
        }
        return dateCopy;
    },
    /**
     * format seconds to minutes, ex. 120 -> 02:00
     */
    formatSecondsToMinutes(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds - minutes * 60;
        const formattedMinutes = `${minutes < 10 ? `0${minutes}` : minutes}`;
        const formattedRemainingSeconds = `${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
        return `${formattedMinutes}:${formattedRemainingSeconds}`;
    },
    /**
     * @deprecated since v1.34.0 - use `formatDateTime()` instead.
     */
    dateToObject(date) {
        const hours24 = date.getHours();
        const weekDays = Vue.$t('weekDays');
        const objectDate = {
            day: weekDays.split(',')[date.getDay()],
            date: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            hours: hours24 % 12 || 12,
            minutes: date.getMinutes(),
            type: hours24 >= 12 ? 'pm' : 'am',
            hours24,
        };

        for (const key in objectDate) {
            if (Number.isInteger(objectDate[key])) {
                objectDate[key] = ('0' + objectDate[key]).slice(-2);
            }
        }

        return objectDate;
    },
    /**
     * @deprecated since v1.34.0 - use `formatDateTime()` instead.
     */
    formatDate(date, offset = 0) {
        const tmp = new Date(new Date(date).getTime() + offset);
        return tmp.getFullYear() + ('0' + (tmp.getMonth() + 1)).slice(-2) + ('0' + tmp.getDate()).slice(-2);
    },
    /*
    Transforms raw datetime into a formatted datetime string in the timezone defined by offset.
    Currently supported datetime formats are:
    1. String without timezone. Example: "2021-03-10T09:41:00" with `isUtc: true`
    2. Epoch milliseconds number. Example: 1615366987000
    3. String without timezone. Example: "2021-03-10 09:41:00"
     */
    formatDateTime(value, options) {
        let dateTime = '';
        const { weekDays = '', isUtc, hour12, timeZoneOffset = 0, toObject = false, needDate = true, needTime = true } = options || {};

        if (!Number.isInteger(timeZoneOffset)) {
            return 'Invalid timezone offset';
        }
        // workaround for non ISO dates && remove and refactor in scope of BP-17851
        if (typeof value === 'string') {
            dateTime = `${value.replace(' ', 'T').replace('Z', '')}Z`;
        } else {
            dateTime = value;
        }
        const tempDate = new Date(dateTime);
        const currentTimezoneOffset = Math.abs(tempDate.getTimezoneOffset() * 60000);
        /* We have to subtract the current timezone offset in two cases:
        1. Opera Presto adds current timezone offset, when datetime string doesn't contain timezone data (e.g. "2021-03-10T09:41:00")
        2. Current timezone offset is added by default, when constructing a Date object from a epoch utc timestamp (e.g. 1615366987000)
        */
        let offset = 0;
        if (isUtc || Number.isInteger(dateTime)) {
            offset -= currentTimezoneOffset;
        } else {
            offset -= currentTimezoneOffset + timeZoneOffset;
        }

        const utcTime = tempDate.getTime() + offset;
        const localDate = new Date(utcTime + timeZoneOffset);

        const day = weekDays && weekDays.split(',')[localDate.getDay()];
        const date = (localDate.getDate() < 10 ? '0' : '') + localDate.getDate();
        let month = localDate.getMonth() + 1;
        month = (month < 10 ? '0' : '') + month;

        const hours = localDate.getHours();
        const minutes = localDate.getMinutes();
        const year = localDate.getFullYear();
        let localeHours;
        let localeMinutes;
        if (hour12) {
            localeHours = hours % 12 || 12;
            localeMinutes = (minutes < 10 ? '0' : '') + minutes;
        } else {
            localeHours = `${hours}`.length === 1 ? `0${hours}` : hours;
            localeMinutes = `${minutes}`.length === 1 ? `0${minutes}` : minutes;
        }

        let ampm = hours >= 12 ? ' pm' : ' am';
        const time = `${localeHours}:${localeMinutes}${hour12 ? ampm : ''}`;

        if (toObject) {
            return { time, day, date, month, year, hours: '', minutes: '', ampm: '' };
        } else if (needDate && needTime) {
            return `${time} ${day} ${date}/${month}`;
        } else if (needDate) {
            return `${day} ${date}/${month}`;
        } else if (needTime) {
            return `${time}`;
        }
    },

    replaceAt(str, index, value) {
        return str.substring(0, index) + value + str.substring(index + 1);
    },
    isEmpty(value) {
        return Object.keys(value).length === 0;
    },
    capitalize(value) {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    deepMerge: deepMerge,
    getTeamIconAddress: function (imageId) {
        if (!imageId) return 'https://d36r55ftass5h5.cloudfront.net/img/emblem/v3/0.png';
        const base = config.isProd()
            ? 'https://d36r55ftass5h5.cloudfront.net/img/emblem/v3/'
            : 'http://d2epz9yirojwy4.cloudfront.net/img/emblem/v3/';
        return base + imageId + '.png';
    },
    formatPhoneNumber(phoneNumber, phonePrefix, jurPhoneNumberFormat) {
        const formattedPrefix = '+' + phonePrefix;
        const jurPhoneNumberFormatRegex = jurPhoneNumberFormat && new RegExp(jurPhoneNumberFormat);
        if (jurPhoneNumberFormatRegex && jurPhoneNumberFormatRegex.test(phoneNumber)) {
            return phoneNumber.startsWith(formattedPrefix) ? phoneNumber : formattedPrefix + phoneNumber;
        }

        if (phoneNumber.startsWith('00' + phonePrefix)) {
            return '+' + phoneNumber.slice(2);
        } else if (phoneNumber.startsWith(phonePrefix)) {
            return '+' + phoneNumber;
        } else if (phoneNumber.startsWith('0')) {
            return '+' + phonePrefix + phoneNumber.slice(1);
        } else if (phoneNumber.startsWith(formattedPrefix)) {
            return phoneNumber;
        } else {
            return formattedPrefix + phoneNumber;
        }
    },
    formatDecimalNumber(value) {
        return value
            .replace(/,/g, '.')
            .replace(/^[.]/, '0.')
            .replace(/[^\d.]+|^0+(?=0(\.|$)|\d)/g, '')
            .replace(/(\.\d*[1-9])0+$/, '$1');
    },
    removeCountryCode(phoneNumber, countryCode) {
        const template = new RegExp(`^\\+*${countryCode}`);
        if (!phoneNumber || !template.test(phoneNumber)) {
            return phoneNumber;
        }
        return phoneNumber.replace(template, '');
    },
    normalizeName(value = '') {
        return value
            .replace(/[-‒–—―‐]+/g, `-`)
            .replace(/[\s]+/g, ` `)
            .replace(/["’]+/g, `'`)
            .trim();
    },
    processErrorResponse(error, defaultMessage) {
        return getObjectField(error, 'message', '') || Vue.$t(defaultMessage || 'critical.error.somethingWentWrong', { indefinite: true });
    },
    setSEOTags(data) {
        if (data.templates && data.templates.title) {
            const title = document.getElementsByTagName('title').item(0);
            const newTitleText = this.interpolate(data.templates.title, data.params);
            if (newTitleText !== title.text) {
                title.text = newTitleText;
            }
        }
        if (data.templates && data.templates.description) {
            const description = document.getElementsByName('description').item(0);
            const newDescriptionContent = this.interpolate(data.templates.description, data.params);
            if (newDescriptionContent !== description.content) {
                description.content = newDescriptionContent;
            }
        }
    },
    processMenuAuth(authString, authStatus) {
        if (authString === '' || authString === undefined) return true;
        // eslint-disable-next-line
        else if (Number(authString) == authStatus) return true;
        else return false;
    },
    processStrapiMenuAuth(authString, isAuthenticated) {
        if (authString === 'always') return true;
        if (!isAuthenticated && authString === 'nonAuth') return true;
        if (isAuthenticated && authString === 'auth') return true;
        return false;
    },
    isVisibleOnPlatform(data) {
        const { hideOnSelected, platforms } = data || {};
        const currentTypes = deviceTypesArray();
        const isVisible = platforms ? platforms.some((platform) => currentTypes.includes(platform)) : true;

        if (hideOnSelected) {
            return !isVisible;
        }
        return isVisible;
    },

    processStrapiMenuLinks({ links, isAuthenticated = false, userStatus, messagesCount, transformer = (it) => it, router }) {
        const hasPageOrExternalUrl = (link) => {
            return !!getObjectField(link, 'page.data') || !!link.externalUrl;
        };
        const shouldShowOnPresto = (link) => {
            return !link.hideOnPresto || (link.hideOnPresto && !deviceType.isPresto());
        };
        const isVisibleForUser = (link, userStatus, isAuthenticated) => {
            return link.visibility && userStatus
                ? this.hasValidVisibility(link.visibility, userStatus)
                : this.processStrapiMenuAuth(link.displayStatus, isAuthenticated);
        };
        return links
            .filter(
                (link) =>
                    link.isLinkEnabled &&
                    isVisibleForUser(link, userStatus, isAuthenticated) &&
                    hasPageOrExternalUrl(link) &&
                    shouldShowOnPresto(link) &&
                    this.isVisibleOnPlatform(link.platformVisibility)
            )
            .map((link) => {
                const { route } = (router && router.resolve({ path: link.path })) || {};
                const routeName = link.externalUrl ? link.name : getObjectField(route, 'name', link.name);
                return transformer({
                    ...link,
                    url: link.externalUrl || link.fullPath,
                    target: link.externalUrl ? '_blank' : '_self',
                    badge: link.badgeType === 'new-messages' && messagesCount && !link.badge ? messagesCount.toString() : link.badge,
                    pageId: routeName === routeNames.GENERIC_PAGE ? `${routeNames.GENERIC_PAGE}.${link.path.replace('/', '')}` : routeName,
                });
            });
    },
    createUUID() {
        // http://www.ietf.org/rfc/rfc4122.txt
        var s = [];
        var hexDigits = '0123456789abcdef';
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        var uuid = s.join('');
        return uuid;
    },
    pathChecker(list, object) {
        return (
            list &&
            list.some((input) => {
                const { path, positive = [], negative = [] } = input;
                const result = (path || input).split('.').reduce((items, prop) => {
                    return (items && items[prop]) || (Array.isArray(items) && items.some((it) => it[prop]));
                }, object);
                const positiveResult = positive.length ? positive.includes(result) : !negative.includes(result);
                return path ? positiveResult : result;
            })
        );
    },

    /**
     * @deprecated
     * you need to use formulateMarketTitle instead of this
     */
    formulateMarketName({ hcp, priceName, marketName, displayName, handicapType, eventName, groupName, groupedName }) {
        if (['ASIAN', 'EUROPEAN'].includes(handicapType)) {
            const marketNameSplit = marketName ? marketName.split(' - ') : [];
            const eventNameSplit = eventName ? eventName.split(' - ') : [];
            const marketDisplayNameSplit = displayName ? displayName.split(' | ') : [];
            const currentMarketName = !displayName ? groupName : marketDisplayNameSplit[0];
            const currentMarketPeriod = groupedName && !displayName ? groupedName.replace('Full Time', 'FT') : marketNameSplit[1];
            const teamHandicap =
                handicapType === 'ASIAN'
                    ? eventNameSplit[priceName === '1' ? 0 : 1] + ' ' + hcp
                    : hcp.replace('Home', eventNameSplit[0]).replace('Away', eventNameSplit[1]);
            return Vue.$t('ui.betslip.betNameTemplates.handicap', {
                marketName: currentMarketName,
                teamHandicap,
                period: currentMarketPeriod,
                priceName,
            });
        } else {
            return Vue.$t(`ui.betslip.betNameTemplates.${hcp ? 'hcp' : 'default'}`, { marketName, hcp, priceName });
        }
    },
    formulateMarketTitle({ eventName, marketName, displayName, priceName, hcp }) {
        if (!displayName) return marketName;
        const [home, away] = eventName.split(' - ');

        const displayNameString = displayName.replace('{home}', home).replace('{away}', away);
        const priceNameString = priceName ? ` (${priceName})` : '';
        const hcpString = hcp ? ` (${hcp})` : '';

        return displayNameString + priceNameString + hcpString;
    },
    formulateLink(link) {
        try {
            const url = new URL(link);
            return url.href;
        } catch (e) {
            return link.startsWith('/') ? link : `//${link}`;
        }
    },
    isInternalLink(url) {
        return !url.startsWith('//') && !url.includes('://');
    },
    checkPathForMatchingCurrentRoute(currentPath, path) {
        if (!currentPath || !path) return false;

        const currentPathNoParams = currentPath.split('?')[0];
        const pathNoParams = path.split('?')[0];

        if (pathNoParams === currentPathNoParams) {
            return true;
        } else if (pathNoParams !== '/') {
            const routeSegments = currentPathNoParams.split('/').filter((segment) => segment.length);
            return pathNoParams
                .split('/')
                .filter((segment) => segment.length)
                .every((segment, index) => routeSegments[index] && routeSegments[index] === segment);
        }
    },
    isObject(item) {
        return typeof item === 'object' && !Array.isArray(item) && item !== null;
    },
    findCurrencyDetails(items, currency) {
        return items.find((item) => item.currency === currency);
    },
    formatCurrencySymbol(currency) {
        return currency.split('%s').join('').trim();
    },
    amountIsNumberRule(value) {
        // isFinite() doesn't work properly with comma separated numbers,
        // must be replaced with dot:
        value = value && value.toString().replace(',', '.');
        return !isNaN(parseFloat(value)) && isFinite(value);
    },
    debounce(fn, timeout, onDebounce = null) {
        let timer;
        return (...args) => {
            typeof onDebounce === 'function' && onDebounce(true);
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                typeof onDebounce === 'function' && onDebounce(false);
                fn.apply(this, args);
            }, timeout);
        };
    },
    hasValidVisibility(visibility, currentUserStatus) {
        if (Array.isArray(visibility)) {
            if (visibility.includes('hidden')) {
                return false;
            }
            return visibility.some((v) => currentUserStatus[v]) || visibility.length === 0;
        } else {
            if (visibility === 'hidden') {
                return false;
            }
            return currentUserStatus[visibility] || visibility === 'all' || !visibility;
        }
    },
    /**
     * Picks the random item based on its weight.
     * The items with higher weight will be picked more often (with a higher probability).
     *
     * For example:
     * - items = [
     * {label:'banana', weight: 0},
     * {label: 'orange', weight: 0.2},
     * {label:'apple', weight: 0.8}
     * ]
     * - weightedRandom(items) in 80% of cases will return 'apple', in 20% of cases will return
     * 'orange' and it will never return 'banana' (because probability of picking the banana is 0%)
     *
     * @param {{weight: number, [key: string]: any}[]} items
     * @returns {item|undefined}
     */
    weightedRandom(items) {
        const cumulativeWeights = [];
        items.forEach((item, i) => {
            let weight = 0;
            if (typeof item.weight === 'number') {
                weight = item.weight;
            } else {
                console.warn(`Item must have a weight field, weight should be a number. Item: ${item}, type: ${typeof item.weight}`);
            }

            cumulativeWeights[i] = weight + (cumulativeWeights[i - 1] || 0);
        });
        const maxCumulativeWeight = cumulativeWeights[cumulativeWeights.length - 1];
        const randomNumber = maxCumulativeWeight * Math.random();
        const itemIndex = cumulativeWeights.findIndex((wght) => wght >= randomNumber);
        return items[itemIndex];
    },
    removeArrayDuplicates(...arrays) {
        if (Array.isArray(arrays)) {
            return Array.from(new Set([...arrays.flat()]));
        }
        console.warn('invalid parameters - "arrays" must be an array', arrays);
        return [];
    },
    /**
     * divides the array into parts
     * @param {Array} arr - array
     * @param {Number} chunkSize - chank size
     * @param {Boolean} fill - whether or not to fill in the missing elements
     * for example, when dividing an array of 5 elements ([0, 1, 2, 3, 4]) into parts of 3,
     * the last part will have only 2 elements ([[0, 1, 2], [3, 4]]).
     * If you set this flag to true, then the last part will be filled with undefined values
     * to the required length([[0, 1, 2], [3, 4, undefined]]) )
     * @returns {Array[]} - array divided into chunks (array of arrays)
     */
    chunk(arr, chunkSize, fill = false) {
        if (chunkSize <= 0) throw new Error('Invalid chunk size');
        const result = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            let newChunk = arr.slice(i, i + chunkSize);
            if (fill && newChunk.length < chunkSize) {
                newChunk = Array.from([...newChunk, ...Array(chunkSize - newChunk.length)]);
            }
            result.push(newChunk);
        }
        return result;
    },

    openUrl(url, target = '_blank') {
        window.open(new URL(url), target);
    },
};

/**
 * Check if the value is undefined or null.
 *
 * @param value {*}
 * @return {boolean}
 */
export function isNil(value) {
    return value === undefined || value === null;
}

/**
 * Safely get a dot-notated path within a nested object,
 * with ability to return a default if the full key path does not exist or the value is undefined
 * prev. implementation https://github.com/developit/dlv
 *
 * @param {Object} obj - object to get value from
 * @param {string | string[]} key - path to the field in the object
 * @param {*} [def] - default value if nothing was found for the provided key
 * @returns {*} - value in the object for the provided path or default value or undefined
 */
export function getObjectField(obj, key, def) {
    if (isNil(obj) || !key) {
        return def;
    }

    const props = key.split('.');

    for (const p of props) {
        obj = obj[p];

        if (isNil(obj)) {
            return def;
        }
    }

    return obj;
}

/**
 * @typedef ComponentLink
 * @type {object}
 * @property {string} link
 * @property {boolean} isActual
 * @property {string} label
 */
/**
 * Returns actual (isActual=true) ComponentLink, otherwise first ComponentLink from array
 *
 * @param {ComponentLink[]} links
 * @return {ComponentLink}
 */
export function getLinkComponent(links) {
    const link = links.find(({ isActual }) => isActual);

    if (link) {
        return link;
    }

    return links[0];
}

// todo: BP-29254 need refactor move numberFormat to core/utils/number/
export function numberFormat(input, decimals = 2, decimalsSeparator = '.', thousandSeparator = ',') {
    const value = typeof input === 'number' && !isNaN(input) ? input : 0;
    const number = value % 1 === 0 ? value.toFixed(decimals) : value.toString();
    const [integer, fractional] = number.split('.');
    const integerFormat = integer.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + thousandSeparator);

    if (!decimals) {
        return thousandSeparator ? integerFormat : integer;
    }

    return integerFormat + decimalsSeparator + fractional.substring(0, decimals).padEnd(decimals, '0');
}

export default { ...helper, getObjectField, numberFormat };
