<script>
export default {
    name: 'Image',
    functional: true,
    props: {
        data: {
            default: () => [],
        },
    },
    render(h, context) {
        const { attrs } = context.props.data;
        const vnodeImage = h('img', {
            class: `align-${attrs.alignment}`,
            style: { width: attrs.stretched ? '100%' : `${attrs.width}${attrs.widthMeasure || 'px'}` },
            attrs: attrs,
        });

        if (attrs.href) {
            return h('a', { attrs: { href: attrs.href, target: attrs.target } }, [vnodeImage]);
        }

        return vnodeImage;
    },
};
</script>
<style lang="scss" scoped>
.align {
    &-left {
        display: block;
        margin-left: 0;
        margin-right: auto;
    }

    &-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &-right {
        display: block;
        margin-left: auto;
        margin-right: 0;
    }
}
</style>
