import Vue from 'vue';
import { ExpansionPanel, Spinner } from '@agi.packages/core/components';
import { DynamicComponent } from '@agi.packages/platform/components';

import ImageIcon from './ImageIcon';
import SvgIcon from './SvgIcon';
import SRWidget from './SRWidget';
import CaptchaWidget from './CaptchaWidget.vue';
import renderer from './renderer';
import Withdraw from '@/components/content/Withdraw';

// External import must be loaded first
Vue.component('Spinner', Spinner);
Vue.component('ExpansionPanel', ExpansionPanel);
Vue.component('DynamicComponent', DynamicComponent);
// Local import must be loaded after external
Vue.component('ImageIcon', ImageIcon);
Vue.component('SRWidget', SRWidget);
Vue.component('CaptchaWidget', CaptchaWidget);
Vue.component('SvgIcon', SvgIcon);
Vue.component('renderer', renderer);
Vue.component('Withdraw', Withdraw);
