<template>
    <h1 v-if="!useCasinoHeadline" :id="data.id">{{ data.text }}</h1>
    <Headline v-else icon="icon-casino" :title="data.text" class="casino-header" />
</template>
<script>
import Headline from '@/components/Headline.vue';

export default {
    name: 'Mainheadline',
    props: ['data'],
    components: { Headline },
    computed: {
        useCasinoHeadline() {
            return this.data.cssClass?.includes('casino-header');
        },
    },
};
</script>
<style scoped lang="scss">
h1 {
    font-size: 1.6rem;
    line-height: 1.2em;
}

.casino-header {
    text-align: center;

    .simple-page & {
        margin: -20px -20px 0;
    }
}
</style>
