<template>
    <div class="loading-page" v-if="showSpinner">
        <LoadingElement />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingElement from '@/components/Fragments/LoadingElement.vue';

export default {
    name: 'LoadingPage',
    components: { LoadingElement },
    props: {
        listen: {
            type: [Array, String],
            default: '',
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('core', ['areLoading', 'isLoading']),
        showSpinner() {
            let isListen = false;
            if (this.listen) {
                isListen = Array.isArray(this.listen) ? this.areLoading(this.listen) : this.isLoading(this.listen);
            }
            return this.visible || isListen;
        },
    },
};
</script>

<style lang="scss">
.loading-page {
    display: flex;
    align-items: center;
    background: $body-background-color;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 99999;
}
</style>
