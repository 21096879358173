<template>
    <div class="pjp-message">
        <div class="pjp-message-icon">
            <SvgIcon iconId="message-operational" />
        </div>
        <div class="pjp-message-text">
            <renderer :input="$t('ui.pjp.betslipMessage', { frequency: this.$t(`ui.pjp.frequency.${this.frequency.toLowerCase()}`) })" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressiveJpBetMessage',
    props: {
        frequency: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.pjp-message {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin-top: 16px;

    &-icon {
        margin-right: 8px;
        width: 32px;
        height: 32px;

        & svg {
            fill: $green-success;
        }
    }

    &-text {
        @extend %body-normal-font-400;
    }
}
</style>
