<template>
    <div>
        <a :href="menuItem.url" :id="menuItem.id" :class="['account-menu-item', menuItem.style]" @click.prevent="$emit('menuItemClicked')">
            <div>
                <SvgIcon v-if="menuItem.icon" class="icon-account-menu" :iconId="menuItem.icon"></SvgIcon>
                <span>{{ menuItem.name }}</span>
                <Badge v-if="menuItem.badge" :text="menuItem.badge" :type="menuItem.badgeType" :bgColor="menuItem.badgeColor" />
            </div>
            <div class="icon-wrapper">
                <SvgIcon
                    :iconId="`${menuItem.flagIcon || 'arrow_right'}`"
                    :class="`${menuItem.flagIcon ? 'icon-menu-flag' : 'icon-arrow-right'}`"
                />
            </div>
        </a>
    </div>
</template>

<script>
const Badge = () => import('@/components/Badge');

export default {
    name: 'MyAccountMenuItem',
    props: {
        menuItem: {
            type: Object,
        },
    },
    components: { Badge },
};
</script>

<style lang="scss" scoped>
.account-menu {
    &-item {
        @extend %menu_item;
        width: 100%;
        text-align: left;
        padding: 12px 8px 12px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon-wrapper {
            width: 12px;
            height: 16px;
        }
        .icon-arrow-right {
            width: 100%;
            height: 100%;
            fill: #aaaeb0;
        }
        .icon-menu-flag {
            width: 12px;
            height: 12px;
            border-radius: 100%;
        }
        span {
            vertical-align: middle;
        }

        .badge {
            margin-left: 4px;
        }

        .icon-account-menu {
            width: 16px;
            height: 16px;
            margin-right: 12px;
            fill: $dark-green;
        }
    }
}
</style>
