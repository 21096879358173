<template>
    <div>
        <Spinner v-if="!items && !polling" :visible="true" class="align-center inset viewport-padding" />
        <div v-else-if="items.length === 0" class="no-data-text">
            <slot name="empty"></slot>
        </div>
        <template v-else>
            <div
                v-for="(bet, index) in items"
                :key="`${bet.id}-${index}`"
                class="bet"
                @click="openBetslip(bet)"
                :data-test-id="`bet-${type}-${bet.id}`"
                :data-test-class="`bet-${type}`"
            >
                <div class="bet-line bet-header">
                    <div>
                        {{ getTime(bet) }}
                        <span class="date">{{ getDate(bet) }}</span>
                        <div class="id" data-test-id="bet-id">
                            {{ $t('ui.myBets.id') }}: #{{ bet.id }}
                            <span v-if="bet.virtualInfo"> - {{ bet.virtualInfo.seasonName }}</span>
                        </div>
                    </div>
                    <div class="status">
                        <template v-if="!isOutcome">
                            <slot name="icon" :bet="bet" />
                            <div>{{ bet.settlementState.settled }}/{{ bet.settlementState.total }}</div>
                        </template>
                        <template v-else>
                            <span v-if="bet.status === betStatus.WON" class="positive">
                                {{ $t('ui.myBets.won').toUpperCase() }}
                            </span>
                            <span v-else-if="bet.status === betStatus.PENDING" class="neutral">
                                {{ $t('ui.myBets.pending').toUpperCase() }}
                            </span>
                            <span v-else-if="bet.status === betStatus.CASHOUT" class="positive">
                                {{ $t('ui.cashout.cashout').toUpperCase() }}
                            </span>
                            <span v-else-if="bet.status === betStatus.CANCELLED" class="neutral">
                                {{ $t('ui.myBets.void').toUpperCase() }}
                            </span>
                            <span v-else class="negative">
                                {{ $t('ui.myBets.lost').toUpperCase() }}
                            </span>
                        </template>
                    </div>
                </div>
                <div class="bet-line">
                    <div class="bet-detail">
                        <span class="label">
                            {{ $t(`ui.myBets.${bet.taxInfo.stakeTaxEnabled ? 'amount' : 'stake'}`).toUpperCase() }}
                        </span>
                        <Currency class="value" :amount="bet.taxInfo.stakeTaxEnabled ? bet.amount : bet.stake" :format="currencyFormat" />
                    </div>
                    <div class="bet-detail">
                        <span class="label">{{ $t('ui.myBets.odds').toUpperCase() }}</span>
                        <span class="value">{{ $numberFormat(bet.payout.totalOdds, undefined, undefined, ' ') }}</span>
                    </div>
                    <div class="bet-detail end">
                        <template v-if="!isOutcome || bet.status === betStatus.WON || (bet.cashoutInfo && bet.cashoutInfo.result)">
                            <span class="label">{{ $t('ui.myBets.payoutInList').toUpperCase() }}</span>
                            <Currency class="value" :class="{ positive: isOutcome }" :amount="getPayout(bet)" :format="currencyFormat" />
                        </template>
                    </div>
                </div>
                <slot :bet="bet" :openBetslip="openBetslip" />
            </div>
        </template>
        <div v-if="isPresto && hasMore" class="show-more">
            <span @click="$emit('load-more')" class="button button-accent">
                {{ $t('ui.myBets.showMore') }}
            </span>
        </div>
    </div>
</template>

<script>
import { betStatus } from '@agi.packages/sport';
import { mapState, mapGetters } from 'vuex';
import { Spinner, Currency } from '@agi.packages/core/components';
import { helper, deviceType } from '@agi.packages/core';
import { getter as platformGetter } from '@agi.packages/platform';
import { routeName } from '@/router/const-name';

export default {
    name: 'BetsList',
    components: { Spinner, Currency },
    data() {
        return {
            betStatus,
            isPresto: deviceType.isPresto(),
        };
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            default: null,
        },
        hasMore: {
            type: Boolean,
            default: false,
        },
        isOutcome: {
            type: Boolean,
            default: true,
        },
        polling: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
        }),
        ...mapState({
            decimalBetPrecision: (state) => state.platform.settings.betting.real.precision,
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
    },
    methods: {
        openBetslip({ id, betslipType }) {
            this.$router.push({ name: routeName.BETSLIP, params: { id, betslipType: betslipType.toLowerCase() } });
        },
        getTime(bet) {
            return helper.formatDateTime(bet.placed, {
                needDate: false,
                ...this.dateOptions,
                isUtc: true,
            });
        },
        getDate(bet) {
            return helper.formatDateTime(bet.placed, {
                needTime: false,
                ...this.dateOptions,
                isUtc: true,
            });
        },
        getPayout({ status, cashoutInfo, payout: { payout }, taxInfo: { compensationAmount } }) {
            if (status === betStatus.CASHOUT) {
                return cashoutInfo.result.net + cashoutInfo.result.whtCompensation;
            }
            return payout + (compensationAmount || 0);
        },
        $numberFormat: helper.numberFormat,
    },
};
</script>

<style lang="scss" scoped>
.no-data-text {
    @extend %body-big-font-400;
    margin: 14px;
    text-align: center;
}

.show-more {
    margin-top: 10px;
    text-align: center;
}

.bet {
    @extend %small-details-font-400;
    border-bottom: $single-betslip-border;
    color: $main-text;
    padding: 8px;

    .bet-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        &:last-child {
            margin-bottom: 0;
        }

        &.bet-header {
            @extend %body-normal-font-500;
            align-items: flex-start;
            margin-bottom: 8px;

            .date {
                font-weight: 700;
            }

            .id {
                @extend %small-details-font-400;
                color: $disabled-text;
            }

            .status {
                color: $betline-header-status-color;
                font-weight: 700;
                text-align: right;
                white-space: nowrap;
                fill: $betline-header-status-color;
            }
        }

        .bet-detail {
            display: flex;
            flex-direction: column;
            font-weight: 700;
            width: 50%;
            padding-right: 4px;

            &.end {
                align-items: flex-end;
                padding-right: 0px;
            }

            &.success {
                .value {
                    color: $green-success;
                }
            }

            .label {
                @extend %caption-font-700;
                color: $disabled-text;
            }

            .value {
                font-weight: 500;
            }
        }

        .positive {
            color: $green-success;
        }

        .neutral {
            color: $disabled-text;
        }

        .negative {
            color: $error-red;
        }
    }
}
</style>
