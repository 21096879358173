<template>
    <div class="withdraw">
        <h2>{{ $t('ui.payment.payout.title') }}</h2>
        <span class="sub-header" v-if="!status.success && provider.payoutType">{{ $t('ui.payment.payout.subTitle') }}</span>
        <Withdraw @providerChange="updateProvider" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getter } from '@agi.packages/payment';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';

export default {
    name: 'WithdrawPage',
    mixins: [PageMixin, SEOMixin],
    data: function () {
        return {
            provider: {},
        };
    },

    computed: {
        ...mapGetters({
            status: getter.GET_PAYOUT_STATUS,
        }),
    },
    methods: {
        updateProvider(provider) {
            this.provider = provider;
        },
    },
};
</script>

<style lang="scss" scoped>
.withdraw {
    padding: 20px;

    .sub-header {
        @extend %body-normal-font-400;
        margin-bottom: 10px;
    }

    h2 {
        @extend %h4-font-700;
        @include maxonecolumn {
            font-size: 1.26rem;
        }
    }
}
</style>
