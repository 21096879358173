<template>
    <div class="input-range-wrapper">
        <input
            class="input-range"
            type="range"
            :min="min"
            :max="max"
            :value="value || 0"
            :disabled="disabled"
            :step="step"
            @input="onValueChanged"
            ref="inputRangeRef"
        />
        <div class="min-label">{{ minText }} {{ numberFormatter(min) }}</div>
        <div class="thumb-label" ref="thumbLabelRef">{{ numberFormatter(value) }}</div>
        <div class="max-label">{{ maxText }} {{ numberFormatter(max) }}</div>
    </div>
</template>

<script>
export default {
    name: 'RangeSlider',
    props: {
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        minText: {
            type: String,
        },
        maxText: {
            type: String,
        },
        step: {
            type: Number,
            default: 1,
        },
        value: {
            type: Number,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        numberFormatter: {
            type: Function,
            default: (number) => number,
        },
    },
    watch: {
        min() {
            this.rePositionThumbLabel();
        },
        max() {
            this.rePositionThumbLabel();
        },
        value() {
            this.rePositionThumbLabel();
        },
    },
    mounted() {
        window.addEventListener('resize', this.rePositionThumbLabel);
        this.rePositionThumbLabel();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.rePositionThumbLabel);
    },
    methods: {
        onValueChanged(e) {
            this.$emit('input', +e.target.value);
        },
        rePositionThumbLabel() {
            this.$nextTick(() => {
                if (!this.$refs.inputRangeRef) return;
                const inputRangeWidth = this.$refs.inputRangeRef.clientWidth;
                const thumbLabelWidth = this.$refs.thumbLabelRef.clientWidth;
                const maxPosition = ((inputRangeWidth - thumbLabelWidth) * (this.max - this.min)) / (this.max - this.min);

                if (this.value < this.min) {
                    this.$refs.thumbLabelRef.style.left = `0px`;
                    return;
                } else if (this.value > this.max) {
                    this.$refs.thumbLabelRef.style.left = `${maxPosition}px`;
                    return;
                }

                // Calculate thumb position relative to inputValue value and max value
                const thumbPosition = ((inputRangeWidth - thumbLabelWidth) * (this.value - this.min)) / (this.max - this.min);
                this.$refs.thumbLabelRef.style.left = `${thumbPosition}px`;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$track-bg-color: $light-grey;
$track-height: 4px;
$thumb-height: 16px;
$thumb-border-shadow: 0 0 0 1px inset $disabled-bg;
$thumb-border-image: linear-gradient(90deg, $green-success 50%, $track-bg-color 0) 0 1 / calc(50% - #{$track-height} / 2) 100vw/0
    calc(100vw);

@mixin disabled-thumb-style {
    background-color: $light-grey;
    box-shadow: 0 0 0 1px inset $disabled-bg;
    border-image: linear-gradient(90deg, $disabled-bg 50%, $track-bg-color 0) 0 1 / calc(50% - #{$track-height} / 2) 100vw/0 calc(100vw);
}

.input-range {
    height: $thumb-height; /* needed for Firefox*/
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    cursor: pointer;
    overflow: hidden;
    bottom: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    margin: 0;
    width: 100%;

    &:not(:disabled):hover ~ .thumb-label {
        visibility: visible;
    }

    &:focus,
    &:active {
        outline: none;
    }
}

/* chromium */
.input-range::-webkit-slider-thumb {
    height: $thumb-height;
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: $thumb-border-shadow;
    border-image: $thumb-border-image;
    -webkit-appearance: none;
    appearance: none;
}

/* Firefox */
.input-range::-moz-range-thumb {
    height: $thumb-height;
    width: $thumb-height;
    background: none;
    border-radius: 50%;
    box-shadow: $thumb-border-shadow;
    border-image: $thumb-border-image;
    -moz-appearance: none;
    appearance: none;
}

.input-range:disabled {
    &::-webkit-slider-thumb {
        @include disabled-thumb-style;
    }
    &::-moz-range-thumb {
        @include disabled-thumb-style;
    }
}

.max-label,
.min-label {
    position: absolute;
    top: 0;
    font-size: 12px;
    font-weight: 400;
    color: $dark-grey-3;
}

.max-label {
    right: 0;
}

.min-label {
    left: 0;
}

.thumb-label {
    font-size: 12px;
    font-weight: 500;
    color: rgba(37, 42, 45, 1);
    background-color: rgba(230, 231, 226, 1);
    z-index: 1;
    padding: 2px 4px;
    border-radius: 4px;
    position: absolute;
    top: -2px;
    height: 16px;
    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: center;
}

.input-range-wrapper {
    position: relative;
    width: 100%;
    height: 35px;
}
</style>
