<template>
    <div class="betline-list">
        <div class="betline-list-data">
            <div v-for="(market, marketIndex) in markets" :key="market.id">
                <Betline
                    v-if="showMarket(market)"
                    @select:price="$emit('select:price', $event)"
                    :key="market.id"
                    :rawDate="event.startTime"
                    :sportId="Number(event.category.id)"
                    :market="market"
                    :fullname="event.name"
                    :eventId="Number(event.id)"
                    :exId="Number(-event.id)"
                    :isLive="event.additionalInfo.live"
                    :isVirtual="event.additionalInfo.virtual"
                    :type="getEventType(event)"
                    :season="season"
                    :availableOpenBetslip="availableOpenBetslip"
                    :isFirstTab="isFirstTab"
                >
                    <template v-slot="{ chunkIndex }">
                        <BetlineCountButton
                            v-if="isShownCountButton"
                            :event="event"
                            :isHidden="chunkIndex > 0 || marketIndex > 0"
                            :isUnavailable="isShownMarketUnavailable"
                        />
                    </template>
                </Betline>
            </div>
            <div v-if="isShownMarketUnavailable">
                <Betline marketUnavailable :sportId="Number(event.category.id)" :isFirstTab="isFirstTab">
                    <template v-slot="{ chunkIndex }">
                        <BetlineCountButton
                            v-if="isShownCountButton"
                            :event="event"
                            :isHidden="chunkIndex > 0"
                            :isUnavailable="isShownMarketUnavailable"
                        />
                    </template>
                </Betline>
            </div>
        </div>
    </div>
</template>

<script>
import { deviceType } from '@agi.packages/core';
import { betslipType } from '@agi.packages/sport';

import Betline from './Betline.vue';
import BetlineCountButton from './BetlineCountButton.vue';

export default {
    name: 'BetlineList',
    components: { Betline, BetlineCountButton },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    props: {
        event: {
            type: Object,
            require: true,
        },
        showMarketCount: {
            type: Boolean,
            default: false,
        },
        availableOpenBetslip: {
            type: Boolean,
            default: false,
        },
        markets: {
            type: Array,
            default: () => [],
        },
        marketGroupToShow: {
            type: Number,
            default: null,
        },
        season: {
            type: Object,
            default: () => ({}),
        },
        isFirstTab: {
            type: Boolean,
        },
    },
    computed: {
        isShownCountButton() {
            return !this.event.additionalInfo.virtual && this.showMarketCount;
        },
        isShownMarketUnavailable() {
            return !this.markets.length;
        },
    },
    methods: {
        getEventType(event) {
            return event.additionalInfo.virtual ? betslipType.VIRTUAL : betslipType.REGULAR; // set event type within event response BP-16141
        },
        showMarket({ marketType }) {
            return !this.marketGroupToShow || this.marketGroupToShow === Number(marketType.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.betline-list {
    padding-bottom: 3px;
    @include all_but_mini {
        display: flex;
        flex: 1;
        flex-direction: row;
        width: 100%;
    }

    @include no_flex_box_supported {
        display: table;
        width: 100%;
    }
}

.betline-list-data {
    @include all_but_mini {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    @include no_flex_box_supported {
        display: table-cell;
        vertical-align: top;
        width: 100%;
    }
}
</style>
