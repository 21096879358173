<template>
    <div class="tab-label">
        <Badge :text="$t('ui.common.new')" type="new" />
        <span class="tab-label-text">{{ brandName }}</span>
        <SvgIcon iconId="icon-betpawa-tv" class="icon-statistics" :class="`tv-icon-light`" />
        <span v-if="isLive" class="pulsing-dot"></span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Badge from '@/components/Badge.vue';

export default {
    name: 'LiveVideoStreamLabel',
    props: {
        isLive: {
            type: Boolean,
            default: false,
        },
    },
    components: { Badge },
    computed: {
        ...mapState({
            brandName: (state) => state.platform.settings.brand.brand,
        }),
    },
};
</script>

<style scoped lang="scss">
.pulsing-dot {
    width: 10px;
    height: 10px;
    background-color: #ff7a00;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
    position: relative;
    top: 2px;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.07;
    }

    100% {
        opacity: 1;
    }
}
</style>
