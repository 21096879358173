<template>
    <div class="events-container" data-test-id="bpEvent" :data-event-id="event.id" :class="automationConst.prematch">
        <router-link :name="`id-${event.id}`" class="event-match" :to="{ name: 'Event', params: { id: event.id } }">
            <div class="row">
                <div class="times">
                    <template v-if="isLive">
                        <p v-if="!!getGameMinute" class="sub-title game-minute">Live {{ getGameMinute }}'</p>
                        <SvgIcon v-else iconId="icon-live" class="icon icon-size-small icon-live" />
                        <SvgIcon v-if="hasVideo" iconId="icon-betpawa-tv" title="Video Stream" class="icon icon-size-small icon-stream" />
                    </template>
                    <template v-else>
                        {{ `${eventTime} ` }}<span class="date">{{ eventDate }}</span>
                    </template>
                </div>
                <div class="icons">
                    <Badge v-if="isTwoUp" type="best-odds" iconName="icon-two-up" />
                    <Badge
                        v-if="isBoosted"
                        class="best-odds-holder"
                        type="best-odds"
                        iconName="icon-best-odds"
                        :iconTooltip="$t('project.sports.boosted.text')"
                    />
                </div>
            </div>
            <div class="row">
                <div class="teams">
                    <p class="title team" :title="sideNames.homeTeam">{{ sideNames.homeTeam }}</p>
                    <p class="title separator">-</p>
                    <p class="title team" :title="sideNames.awayTeam">{{ sideNames.awayTeam }}</p>
                </div>
                <div v-if="isLive && event.scoreboard" class="scores">
                    <p class="title score">{{ matchScore.scoreHome }}</p>
                    <p class="title separator">-</p>
                    <p class="title score">{{ matchScore.scoreAway }}</p>
                </div>
            </div>
            <div v-if="!event.additionalInfo.virtual" class="row">
                <p class="sub-title league" data-test-id="eventPath">
                    {{ event.category.name }} / {{ event.region.name }} / {{ event.competition.name }}
                </p>
            </div>
        </router-link>
        <BetlineList
            class="event-betline"
            :markets="this.event.markets"
            :event="event"
            :showMarketCount="showMarketCount"
            :marketGroupsToShow="marketGroupsToShow"
            :isFirstTab="isFirstTab"
        />
        <OpenBetslip v-if="isPresto && selectedEventId === Number(event.id)" iconId="arrow_right" :pointer="true" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { helper, deviceType } from '@agi.packages/core';

import automationConstants from '@/js/automation-const';
import { getter as platformGetter } from '@agi.packages/platform';
import { widgetType } from '@agi.packages/sport';
import { getEventWidget } from '@agi.packages/sport/utils/event/get-event-widget';

import OpenBetslip from './OpenBetslip.vue';
import BetlineList from './BetlineList.vue';
import Badge from '@/components/Badge.vue';

export default {
    name: 'Game',
    components: { Badge, OpenBetslip, BetlineList },
    props: ['event', 'showMarketCount', 'marketGroupsToShow', 'isFirstTab'],
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        getGameMinute() {
            const { scoreboard } = this.event;
            return scoreboard && scoreboard.display && scoreboard.display.minute;
        },
        matchScore() {
            return helper.getObjectField(this.event, 'scoreboard.matchScore', {
                scoreHome: '-',
                scoreAway: '-',
            });
        },
        automationConst() {
            return automationConstants;
        },
        sideNames() {
            const names = this.event.name.split(' - ');
            return {
                homeTeam: names[0],
                awayTeam: names[1],
            };
        },
        eventTime() {
            return helper.formatDateTime(this.event.startTime, {
                needDate: false,
                ...this.dateOptions,
                isUtc: true,
            });
        },
        eventDate() {
            return helper.formatDateTime(this.event.startTime, {
                needTime: false,
                ...this.dateOptions,
                isUtc: true,
            });
        },
        markets() {
            return helper.getObjectField(this, 'event.markets', []);
        },
        isTwoUp() {
            return helper.getObjectField(this.markets[0], 'additionalInfo.twoUp', false);
        },
        isBoosted() {
            return helper.getObjectField(this.markets[0], 'additionalInfo.boosted', false);
        },
        hasVideo() {
            const { isStatsPerformEnabled } = this.brandPreference;
            return (
                isStatsPerformEnabled &&
                getEventWidget(this.event, { wantedWidgetType: widgetType.STAT_PERFORM_LIVE_STREAM, defaultValue: undefined })
            );
        },
        ...mapState({
            selectedEventId: (state) => state.sport.betslip.selectedEventId,
        }),
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        isLive() {
            return helper.getObjectField(this.event, 'additionalInfo.live', false);
        },
    },
};
</script>

<style scoped lang="scss">
.events-container {
    padding: 8px 12px 0;
}

.event-match {
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: start;

    @include no_flex_box_supported {
        display: table;
        width: 100%;
        padding-bottom: 8px;
    }

    &:hover {
        color: inherit;
    }

    .row {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;

        @include no_flex_box_supported {
            display: table-row;
        }
    }

    .times {
        @extend %body-normal-font-400;

        @include no_flex_box_supported {
            display: table-cell;
            vertical-align: top;
            padding-bottom: 4px;
        }

        @include oldoldschool {
            font-size: 18px;
        }
    }

    .date {
        @extend %body-normal-font-700;
    }

    .icons {
        display: flex;
        flex-wrap: nowrap;
        justify-content: end;
        align-items: center;

        @include no_flex_box_supported {
            display: table-cell;
            white-space: nowrap;
            vertical-align: top;
            text-align: right;
        }
    }

    .best-odds-holder {
        margin-left: 4px;
    }

    .icon {
        fill: $game-features-icons-fill;
        color: $game-features-icons-fill;
        margin-left: 6px;
        display: inline-block;
        height: 16px;

        &-boosted:last-child {
            margin-right: -2px;
        }

        @include maxmain {
            margin-left: 2px;
        }
    }

    .icon-live {
        fill: $game-status-icon-fill;
        color: $game-status-icon-fill;
        margin-left: 0;
    }

    .icon-stream {
        fill: $dark-green;
        margin: 0 5px;
    }

    .teams,
    .scores {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include no_flex_box_supported {
            display: table-cell;
            vertical-align: top;

            .score,
            .team {
                padding-bottom: 1px;
                display: block;
            }
        }
    }

    .teams {
        line-height: 22px;
        padding: 4px 0;

        @include no_flex_box_supported {
            width: 90%;
            padding-bottom: 4px;
        }
    }

    .scores {
        margin-left: 24px;
        font-size: 18px;
        line-height: 22px;
        padding: 4px 0;

        .score,
        .separator {
            color: $game-status-icon;
        }

        .score {
            text-align: right;
            white-space: nowrap;
        }
    }

    .sub-title {
        color: $disabled-text;
        font-size: 18px;
        padding-top: 5px;

        &.game-minute,
        &.league {
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 7px;

            @include maxmain {
                font-size: 12px;
            }
        }

        &.game-minute {
            @extend %body-normal-font-400;
            color: $game-game-minute-color;
            font-weight: normal;
        }

        &.league {
            @extend %small-details-font-400;
            font-style: $game-league-font-style;

            @include oldoldschool {
                font-size: 18px;
            }
        }
    }

    .title {
        @extend %h4-font-700;

        display: inline-block;
        margin: 0;
        text-transform: none;
        width: auto;

        &.separator {
            display: none;
        }
    }
}
</style>
