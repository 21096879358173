<template>
    <div class="otp-limits">
        <div class="otp-limits-label" @click.stop="toggleModalVisibility(true)">
            {{ $t('ui.accountVerification.otpLimits.label', { limit: limits.attemptsLimit, period }) }}
            <SvgIcon class="icon-info" iconId="icon-info" />
        </div>
        <Modal name="limit-info" :class="{ 'limit-info': true, presto: isPresto }" width="100%" @close="toggleModalVisibility(false)">
            {{ $t('ui.accountVerification.otpLimits.info', { limit: limits.attemptsLimit, period }) }}
        </Modal>
    </div>
</template>

<script>
import { helper, deviceType } from '@/modules/core';

export default {
    name: 'OtpLimitsLabel',
    props: {
        limits: {
            type: Object,
            required: true,
        },
    },
    computed: {
        period() {
            return helper.formatMinutesToDHM(this.limits.attemptsPeriodMin, { biggestUnit: 'day', maxUnits: 1, isNumberOneVisible: false });
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    methods: {
        toggleModalVisibility(visible) {
            this.$emit('toggleModal', visible);

            visible === true ? this.$modal.show('limit-info') : this.$modal.hide('limit-info');
        },
    },
};
</script>

<style scoped lang="scss">
.otp-limits {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &-label {
        font-size: $small-link-font-size;
        line-height: 16px;
        color: $main-text;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .icon-info {
        width: 12px;
        height: 12px;
        margin-left: 4px;
        max-height: 12px;
        max-width: 12px;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
.limit-info {
    top: 100%;
    font-size: $body-normal-font-size;

    &.presto {
        position: relative;
        top: 0;
    }

    ::v-deep .modal-wrapper {
        margin: 0;
    }
}
</style>
