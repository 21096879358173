<template>
    <div class="hot-description">
        <SvgIcon iconId="icon-hot" class="icon-hot" />
        <span>{{ $t('ui.hotMessage') }}</span>
    </div>
</template>

<script>
export default {
    name: 'HotDescription',
};
</script>

<style scoped lang="scss">
.hot-description {
    font-size: 14px;
    color: $hot-description-color;
    padding: 2%;
    border-top: 1px solid $hot-description-border-color;
    text-align: center;
}

.icon-hot {
    width: 14px;
    height: 14px;
    margin: 0 3px 3px;
    fill: $betline-icon-hot-fill;
}
</style>
