<template>
    <div class="events-container" :data-event-id="`${event.id}`">
        <div v-if="live">
            <div class="live">
                <h3 class="title">{{ event.homeTeam.name }} - {{ event.awayTeam.name }}</h3>
                <div class="score">
                    <span v-if="showHTScore" class="half-time">
                        ({{ event.homeHTScore || event.homeHT || 0 }} - {{ event.awayHTScore || event.awayHT || 0 }})
                    </span>
                    <span>{{ event.homeScore }} - {{ event.awayScore }}</span>
                </div>
            </div>
        </div>
        <div v-else class="events-list">
            <div class="name">
                <h3 class="title">{{ event.name }}</h3>
            </div>
            <BetlineList
                :markets="event.markets"
                :event="event"
                :season="season"
                :availableOpenBetslip="true"
                :marketGroupToShow="marketGroupToShow"
            />
        </div>
    </div>
</template>

<script>
import BetlineList from '../../BetlineList.vue';

export default {
    name: 'VirtualGame',
    components: { BetlineList },
    props: {
        event: {
            type: Object,
            require: true,
        },
        marketGroupToShow: {
            type: Number,
            default: null,
        },
        live: {
            type: Boolean,
            default: false,
        },
        season: {
            type: Object,
            default: () => ({}),
        },
        showHTScore: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.events-container {
    @include no_flex_box_supported {
        display: table;
        width: 100%;
    }
}
.name {
    padding: 8px 0px 0;
}

.events-list {
    padding: 0 12px;
}

.live {
    .title {
        float: left;
        padding: 12px;
    }

    .score {
        float: right;
        padding: 12px;

        .half-time {
            @extend %h4-font-400;
            color: $grey-text;
            margin-right: 10px;
        }
    }
}

.title {
    @extend %h4-font-700;
    text-transform: none;
    margin: 0;
    @include oldschool {
        font-size: 14px;
    }
}
</style>
