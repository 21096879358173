<template>
    <div>
        <div v-if="!link || noWrap">
            <slot />
        </div>
        <div v-else-if="isInternal" @click="navigate">
            <slot />
        </div>
        <a v-else :href="href" :target="target" @click="$emit('clicked')" :class="{ 'highlight-hover-disabled': noHover }">
            <slot />
        </a>
    </div>
</template>

<script>
/* We need this wrapper component since router link currently doesn't support external links
For further details have a look at https://github.com/vuejs/vue-router/issues/1280 */
import { helper } from '@agi.packages/core';

export default {
    name: 'LinkWrapper',
    props: {
        link: {
            type: String,
            require: true,
        },
        noWrap: {
            type: Boolean,
            default: false,
        },
        replace: {
            type: Boolean,
            default: false,
        },
        noHover: {
            type: Boolean,
            default: false,
        },
        target: {
            type: String,
            default: '_blank',
        },
    },
    computed: {
        href() {
            return this.$formulateLink(this.link);
        },
        isInternal() {
            return this.$isInternalLink(this.link);
        },
    },
    methods: {
        $formulateLink: helper.formulateLink,
        $isInternalLink: helper.isInternalLink,
        navigate() {
            this.$emit('clicked');
            this.$router.push({ path: this.href, replace: this.replace });
        },
    },
};
</script>
