<template>
    <div class="my-account-page">
        <UserPhoneOperator :providerIcon="providerIcon" :show-verified-status="true" />
        <div class="account-menu-contents">
            <MyAccountMenuItem
                v-if="language.languageList.length > 1"
                :key="languageSelectorMenuItem.id"
                :menuItem="languageSelectorMenuItem"
                @menuItemClicked="switchLang()"
            />
            <MyAccountMenuItem
                v-for="(menuItem, index) in hasStrapiAccountMenu ? accountMenuLinks : accountMenuContents"
                :key="menuItem.name + '-menu-item-' + index"
                :menuItem="{ ...menuItem, name: hasStrapiAccountMenu ? menuItem.name : $t(menuItem.key, { pinOrPassword }) }"
                @menuItemClicked="onAccountMenuItemSelect(menuItem)"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { action as platformAction, auth, getter as platformGetter } from '@agi.packages/platform';
import { deviceType, getter as coreGetter, helper, processMenu } from '@agi.packages/core';

import { getter as translationGetter } from '@/store/modules/translations';
import { UserPhoneOperator } from '@agi.packages/payment/components';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import MyAccountMenuItem from '@/modules/platform/components/content/MyAccountMenuItem.vue';
import { telcoIcon } from '@/modules/payment';

const DOWNLOAD_MENU_ITEM = 'android-app';

export default {
    name: 'MyAccount',
    components: { MyAccountMenuItem, UserPhoneOperator },
    mixins: [PageMixin, SEOMixin],
    data() {
        return {
            isWebOrAndroidWeb: deviceType.isWebOrAndroidWeb(),
        };
    },
    computed: {
        ...mapGetters({
            user: platformGetter.GET_USER_SETTINGS,
            preference: platformGetter.GET_PREFERENCE,
            pinOrPassword: translationGetter.PIN_OR_PASSWORD,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            settings: platformGetter.GET_SETTINGS,
            language: translationGetter.LANGUAGE,
            isLoading: coreGetter.IS_LOADING,
            currentUserStatus: platformGetter.GET_CURRENT_USER_STATUS,
            depositOptionByPhoneOperator: platformGetter.GET_DEPOSIT_OPTION_BY_PHONE_OPERATOR,
            depositOptionByPreferredPaymentProvider: platformGetter.GET_DEPOSIT_OPTION_BY_PREFERRED_PAYMENT_PROVIDER,
        }),
        ...mapState({
            accountMenuContents(state) {
                return state.menus.top
                    .map((item) => {
                        const newItem = processMenu.processDefaultItemBadge({ item, newMessagesNumber: this.newMessagesNumber });
                        return newItem.key === 'menus.changePassword'
                            ? { ...newItem, key: `ui.common.${this.pinOrPassword}.changePassword` }
                            : newItem;
                    })
                    .filter((item) => {
                        if (item.key === 'menus.myAccount') {
                            return;
                        }
                        if (item.enabler && !helper.pathChecker(item.enabler, this.settings)) {
                            return;
                        }
                        if (item.name === DOWNLOAD_MENU_ITEM && !this.isWebOrAndroidWeb) {
                            return;
                        }
                        return helper.processMenuAuth(item.requireAuth, this.isAuthenticated);
                    });
            },
            newMessagesNumber: (state) => state.platform.messaging.notSeen,
            hasStrapiAccountMenu: (state) => state.platform.config?.accountMenu,
            accountMenuLinks(state) {
                if (!state.platform.config.accountMenu) return [];
                return helper.processStrapiMenuLinks({
                    links: helper.getObjectField(state.platform.config.accountMenu, 'links', []),
                    userStatus: this.currentUserStatus,
                    isAuthenticated: this.isAuthenticated,
                    messagesCount: this.newMessagesNumber,
                    router: this.$router,
                });
            },
            userPhoneOperatorNetworkName() {
                return this.user.phoneOperatorNetwork || '';
            },
            preferredPaymentProviderName() {
                return this.preference.preferred_payment_provider || '';
            },
            depositChannelIcon() {
                return (
                    telcoIcon[(this.preferredPaymentProviderName || '').toUpperCase()] ||
                    telcoIcon[(this.userPhoneOperatorNetworkName || '').toUpperCase()] ||
                    telcoIcon.DEFAULT
                );
            },
            providerIcon() {
                const iconUrlFromDepositOptionInStrapi = helper.getObjectField(
                    this.depositOptionByPreferredPaymentProvider || this.depositOptionByPhoneOperator,
                    'attributes.icon.data.attributes.url'
                );
                return iconUrlFromDepositOptionInStrapi || `img/providers/${this.depositChannelIcon}.png`;
            },
        }),
        nextAvailableLanguage() {
            const { language, languageList } = this.language;
            return [...languageList].filter((lang) => lang !== language).pop();
        },
        languageSelectorMenuItem() {
            return {
                id: 'language-selector-menu',
                icon: 'icon-language-menu',
                name: this.$t(`otherLanguages.${this.nextAvailableLanguage}.languageChangeText`),
                flagIcon: `icon-language-menu-${this.nextAvailableLanguage}`,
            };
        },
        isLangUpdateInProgress() {
            return this.isLoading(platformAction.UPDATE_LANGUAGE_PREFERENCE);
        },
    },
    methods: {
        onAccountMenuItemSelect(menuItem) {
            if (menuItem.externalUrl) {
                helper.openUrl(menuItem.externalUrl, menuItem.target);
                return;
            }
            this.$router.push({ path: menuItem.url });
        },
        switchLang() {
            if (this.isLangUpdateInProgress) return;
            this.$store.dispatch(platformAction.CHANGE_SITE_LANGUAGE, {
                language: this.nextAvailableLanguage,
                component: 'MyAccount',
            });
        },
    },
    created() {
        this.setSEOTags(
            {
                meta: {
                    seo: {
                        template: 'myAccount',
                        page: 'myAccount',
                    },
                },
            },
            {
                changePasswordOrPin: this.$t(`ui.common.${this.pinOrPassword}.changeYourPassword`),
            }
        );
    },
};
</script>

<style lang="scss" scoped>
.my-account-page {
    padding: 12px;
    background: $light-grey-bg;
    .user-phone {
        margin: 0;
        background: $white-bg;
    }
    .account-menu {
        &-contents {
            background: $white-bg;
            color: $main-text;
            margin-top: 8px;
            > div:not(:last-child) {
                border-bottom: 1px solid $light-grey;
            }
        }
    }
}
</style>
