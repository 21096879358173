<template>
    <div class="single-event" :class="data.cssClass">
        <EventNetworkError v-if="error" :error="error" :isLive="isLive" />
        <Event v-else-if="event" :event="event" :marketId="marketFilter">
            <template slot="widget">
                <SportRadar :matchId="matchId" :isLive="isLive" />
            </template>
        </Event>
        <Spinner v-else-if="eventIsLoading" :visible="true" type="bp-spinner" :zoom="100" class="justify-center" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { getter as coreGetter } from '@agi.packages/core';
import { sport, marketPreferenceType, marketTypeCategory } from '@agi.packages/sport';
import { Spinner } from '@agi.packages/core/components';
import { Event, EventNetworkError, SportRadar } from '@agi.packages/sport/components';
import { convertStringToArrayOfIntegers } from '@agi.packages/sport/store/utils';
import { getEventWidget } from '@agi.packages/sport/utils/event/get-event-widget';

export default {
    name: 'SingleEventView',
    components: { Event, Spinner, EventNetworkError, SportRadar },
    props: ['data'],
    computed: {
        ...mapState({
            errors: (state) => state.sport.sports.singleEvent.errors,
        }),
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
        }),
        error() {
            return this.errors.find((event) => event.eventId === this.eventId && event.marketId === this.marketFilter);
        },
        event() {
            return this.$store.getters[sport.getter.GET_EVENT_WITH_MARKETS_BY_ID](this.data);
        },
        eventId() {
            return this.data.id;
        },
        matchId() {
            return getEventWidget(this.event).id;
        },
        marketFilter() {
            return this.data.market;
        },
        isLive() {
            return !!this.event?.additionalInfo.live;
        },
        eventIsLoading() {
            return (!this.event || !this.error) && this.isLoading(sport.action.GET_SINGLE_EVENT_BY_ID);
        },
        marketFilters() {
            switch (this.marketFilter) {
                case marketTypeCategory.ALL:
                    return {
                        marketPreference: marketPreferenceType.ALL,
                    };
                case marketTypeCategory.MAIN:
                case marketTypeCategory.NONE:
                    return {
                        marketPreference: marketPreferenceType.MAIN,
                    };
                default:
                    let marketsIdArr = this.marketFilter && convertStringToArrayOfIntegers(this.marketFilter);
                    if (marketsIdArr?.length) {
                        return {
                            marketPreference: marketPreferenceType.DEFINED,
                            marketTypeIds: marketsIdArr,
                        };
                    }
                    return {
                        marketPreference: marketPreferenceType.MAIN,
                    };
            }
        },
    },
    mounted() {
        this.$store.commit(sport.mutation.RESET_SINGLE_EVENT_ERROR);
        this.$store.dispatch(sport.action.GET_SINGLE_EVENT_BY_ID, {
            eventIds: [this.eventId],
            marketFilters: this.marketFilters,
        });
    },
};
</script>
