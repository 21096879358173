<template>
    <InputField :name="name" :formName="formName" :value="formattedValue" @value="onInputChange" v-bind="{ ...$attrs, ...$props }" />
</template>

<script>
import InputField from '@/modules/core/components/molecules/InputField.vue';

export default {
    name: 'DecimalInputField',
    props: {
        value: { required: true },
        formName: { required: true },
        name: { required: true },
        type: {
            default: 'text',
        },
        currencySymbol: {
            type: String,
            default: '',
        },
        helpText: {
            type: [String, Boolean],
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        v: {
            type: Object,
        },
        errorMessages: {
            type: Object,
            default: () => ({}),
        },
    },
    components: { InputField },
    computed: {
        formattedValue() {
            return this.formatDecimalInput(this.value);
        },
    },
    methods: {
        onInputChange(value) {
            const finalValue = this.formatDecimalInput(value);
            if (finalValue === null) return;
            this.$emit('value', finalValue);
        },
        formatDecimalInput(value) {
            const isDecimalRegex = /^[0-9]*\.?[0-9]{0,2}$/;
            return !isDecimalRegex.test(value) ? null : value;
        },
    },
};
</script>

<style scoped lang="scss"></style>
