<template>
    <div>
        <div
            class="betslip-bet"
            :class="{ 'not-available': isOfflinePrice(bet.price) || bet.rejectPrice || !bet.price }"
            v-for="bet in bets"
            :key="bet.eventId"
        >
            <div class="remove-bet" @click="$emit('remove', bet)">
                <SvgIcon
                    class="icon-remove"
                    :class="{
                        offline: isOfflinePrice(bet.price),
                        'without-hover': $mq.isSmall,
                    }"
                    iconId="icon-cross"
                />
            </div>
            <div class="betslip-game">
                <div class="game-info">
                    <span class="bet-match">
                        <EventNameWithLink
                            :title="bet.name"
                            :eventId="bet.eventId"
                            :forceTextView="isPresto"
                            :isLink="
                                $isEventLive({
                                    startDate: bet.rawDate,
                                    isVirtual: isVirtual(bet),
                                })
                            "
                        />
                    </span>
                </div>
                <div class="bet-chosen">
                    <span class="bet-pick" :class="{ 'with-not-available': bet.previousPrice }">
                        <SvgIcon
                            v-if="bet.isLive"
                            iconId="icon-live"
                            class="icon-status"
                            :class="{ 'not-available': isOfflinePrice(bet.price) }"
                        />
                        {{ generateSelectionName(bet) }}
                    </span>
                    <div class="bet-price">
                        <Badge v-if="bet.market.twoUp" type="best-odds" iconName="icon-two-up" />
                        <template v-if="offlinePrices.includes(bet.price.Id) || !bet.price.PriceRaw">
                            <span class="previous-price">
                                {{ $t('ui.betslip.notAvailable') }}
                            </span>
                        </template>
                        <template v-else-if="!bet.rejectPrice">
                            <span v-if="bet.previousPrice && bet.previousPrice.price" class="previous-price">
                                {{ $t('ui.betslip.previousPrice', { price: bet.previousPrice.price }) }}
                            </span>
                            {{ isOfflinePrice(bet.price) ? $t('ui.betslip.notAvailable') : bet.price.Price }}
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { deviceType, helper } from '@agi.packages/core';
import { getter as generalGetter } from '@agi.packages/platform';
import { betslipType } from '@agi.packages/sport';
import { isEventLive } from '@agi.packages/sport/utils/event/is-event-live';
import { EventNameWithLink } from '@agi.packages/sport/components';
import Badge from '@/components/Badge.vue';

export default {
    name: 'BetList',
    components: { Badge, EventNameWithLink },
    props: {
        bets: {
            require: true,
            type: Array,
        },
        offlinePrices: Array,
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        ...mapGetters({
            cashoutVisibility: generalGetter.GET_CASHOUT_VISIBILITY,
        }),
    },
    methods: {
        $interpolate: helper.interpolate,
        $isEventLive: isEventLive,
        generateSelectionName(data) {
            const { market: { name, displayName } = {}, price: { Hcp: hcp, Name: priceName } = {}, name: eventName } = data || {};

            return helper.formulateMarketTitle({
                eventName,
                marketName: name,
                displayName,
                priceName,
                hcp,
            });
        },
        isOfflinePrice(price) {
            return this.offlinePrices.includes(price.Id) || !price.PriceRaw;
        },
        isVirtual(bet) {
            return bet.type === betslipType.VIRTUAL;
        },
    },
};
</script>

<style scoped lang="scss">
.betslip-bet {
    display: flex;
    border-top: 1px solid $betslip-border-color;
    align-items: center;
    background: $betslip-betlist-bet-background;

    &.not-available {
        border-right: 4px solid $dark-red;
        background: $betslip-betlist-bet-background-error;
    }
}
.betslip-game {
    flex-grow: 1;
    padding: 8px;
    background: $betslip-game-background;
}

.bet-match {
    color: $grey-text;
}

.remove-bet {
    flex-basis: 8%;
    text-align: center;

    @include oldschool {
        padding-right: 5px;
    }

    .icon-remove {
        width: 12px;
        height: 12px;
        transform: translateY(-6%);
        vertical-align: middle;
        fill: $betslip-betlist-bet-icon;
        cursor: pointer;

        &:not(.without-hover):hover,
        &.offline {
            fill: $error-red;
        }
    }
}

.game-info {
    @extend %small-details-font-400;
    display: flex;
    justify-content: space-between;
}

.bet-chosen {
    @extend %small-details-font-700;
    display: flex;

    .previous-price {
        color: $light-red;
    }
}

.bet-price {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: auto;
    padding-left: 8px;
}

.betslip-game {
    flex-basis: 90%;
}

.bet-pick {
    width: 100%;

    &.with-not-available {
        width: 50%;
    }
}
</style>
