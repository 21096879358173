import { render, staticRenderFns } from "./OnSiteMessage.vue?vue&type=template&id=f40df2e6&scoped=true"
import script from "./OnSiteMessage.vue?vue&type=script&lang=js"
export * from "./OnSiteMessage.vue?vue&type=script&lang=js"
import style0 from "./OnSiteMessage.vue?vue&type=style&index=0&id=f40df2e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f40df2e6",
  null
  
)

export default component.exports