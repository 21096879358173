var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside:[true]",value:(_vm.closeFilter),expression:"closeFilter",arg:true}],class:[
        'event-filter',
        {
            open: _vm.activeFilterKey,
            'non-fullscreen-filter': _vm.activeFilter && !_vm.activeFilter.isFullscreen(),
            'filter-hidden': _vm.hide,
            'event-filter-has-selected': _vm.hasSelectedItems,
        } ]},[_c('div',{staticClass:"event-filter-content"},[_c('div',{staticClass:"event-filter-head"},_vm._l((_vm.filters),function(filter){return _c('div',{key:filter.key,staticClass:"filter-item",class:{
                    'filter-item-grow': filter.text,
                    'filter-item-open': _vm.activeFilterKey === filter.key,
                },attrs:{"data-test-id":("filters-" + (filter.key))},on:{"click":function($event){return _vm.changeFilter(filter)}}},[(filter.iconId)?_c('div',{staticClass:"icon-wrapper"},[_c('SvgIcon',{staticClass:"icon icon-size-medium",attrs:{"iconId":filter.iconId}})],1):_vm._e(),(filter.text)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(filter.text))]):_vm._e(),_c('div',{staticClass:"state-icon-wrapper"},[_c('SvgIcon',{staticClass:"state-icon icon-size-very-small",attrs:{"iconId":_vm.activeFilterKey === filter.key ? 'icon-arrow-up' : 'arrow_down'}})],1)])}),0),(_vm.activeFilterKey)?_c('div',{staticClass:"event-filter-options-list event-filter-options-list-mobile"},[_c('div',{staticClass:"options-list",class:{
                    'options-list-without-padding': _vm.activeFilter && !_vm.activeFilter.hasPaddings(),
                    'options-list-mobile': !_vm.desktopBehavior,
                }},[(_vm.activeFilterKey === _vm.filterKeys.MARKET && _vm.marketGroups.length)?_c('FilterByMarket',{attrs:{"options":_vm.marketGroups},on:{"onClick":_vm.handleMarketClick}}):_vm._e(),(_vm.activeFilterKey === _vm.filterKeys.SPORT_TYPE)?_c('FilterBySport',{attrs:{"options":_vm.sportTypeList},on:{"onClick":_vm.handleSportTypeClick}}):_vm._e(),(_vm.activeFilterKey === _vm.filterKeys.TOURNAMENT)?_c('FilterByTournament',{attrs:{"tournaments":_vm.tournaments,"competitions":_vm.competitions},on:{"onAllCheck":_vm.onAllCompetitionsCheck,"onCheck":_vm.onCompetitionCheck}}):_vm._e()],1),(_vm.activeFilter.hasFooter())?_c('div',{staticClass:"event-filter-actions"},[_c('button',{staticClass:"button button-accent",on:{"click":_vm.resetFilters}},[_vm._v(_vm._s(_vm.$t('ui.eventFilters.resetFilters')))]),_c('button',{staticClass:"button button-primary",on:{"click":_vm.applyFilters}},[_vm._v(_vm._s(_vm.$t('ui.eventFilters.apply')))])]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"event-filter-footer"},[_c('div',{staticClass:"event-filter-selected-list"},[_c('FilterSelectedItemList',{attrs:{"queryMarket":_vm.queryMarket},on:{"selectedItemsChanged":_vm.selectedItemsChanged,"onRemove":_vm.removeCompetitions}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }